import style from "./Button.module.css";
import cn from "classnames";

type Props = {
  color: string;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  imgSrc?: string;
  className?: string;
};

export const Button: React.FC<Props> = ({
  color,
  text,
  imgSrc,
  disabled = false,
  onClick,
  className,
}) => {
  return (
    <button
      disabled={disabled}
      className={cn(`${className}`, {
        [style.button_main]: color === "blue",
        [style.button_second]: color === "white",
        [style.button_disabled]: disabled,
      })}
      onClick={onClick}
    >
      {imgSrc ? (
        <img
          src={imgSrc}
          alt="edit icon"
        />
      ) : null}
      <span className={style.text}>{text}</span>
    </button>
  );
};
