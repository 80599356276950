import { AboutCardItem } from "../AboutCardItem/AboutCardItem";
import { Title } from "../Title/Title";
import styles from "./AboutSection.module.css";

export const AboutSection = () => {
  return (
    <>
      <Title title="About Us" />
      <div className={styles.wrapper}>
        <AboutCardItem
          title="Our mission"
          text="At Raisly, we believe in empowering individuals to achieve their career goals. Our mission is to provide comprehensive tools and resources to help you succeed in every stage of your career journey."
        />
        <AboutCardItem
          title="Our Story"
          text="Raisly was founded by individuals frustrated with the complexities of job hunting and career advancement. We created a streamlined solution to simplify and accelerate career development, making it more empowering and less overwhelming."
        />
        <AboutCardItem
          title="Why Choose Us?"
          text="We understand your struggles because we've been there ourselves. We know your problems, we solve them."
        />
      </div>
    </>
  );
};
