import React, { useCallback, useEffect, useState } from "react";
import style from "./ExportModal.module.css";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../Button/Button";
import { downloadVacancies } from "../../service/downloadVacancies/downloadVacancies";
import { useAuth } from "../../context/AuthContext";
import { Job, useJobContext } from "../../context/JobContext";

interface ExportModalProps {
  onClose: () => void;
  vacancies: Job[];
}

const ExportModal: React.FC<ExportModalProps> = ({ onClose, vacancies }) => {
  const { jobs, filterDates } = useJobContext();
  const [formatSelect, setFormatSelect] = useState("xlsx");
  const [range, setRange] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorTimeout, setErrorTimeout] = useState<NodeJS.Timeout | null>(null);
  const [hasVacancies, setHasVacancies] = useState<boolean | null>(null);
  console.log(`hasVacancies ${hasVacancies}`);

  const token = localStorage.getItem("authToken") || "";

  const checkForVacancies = useCallback(async () => {
    try {
      const response = await fetch("https://raislyhq.com/api/vacancy-list/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          items_per_page: 1,
          page: 1,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setHasVacancies(data.vacancies.length > 0);
    } catch (error) {
      console.error("Error checking for vacancies:", error);
      setHasVacancies(false);
    }
  }, [token]);

  useEffect(() => {
    checkForVacancies();
  }, [checkForVacancies]);

  useEffect(() => {
    setStartDate(filterDates.startDate);
    setEndDate(filterDates.endDate);
  }, [filterDates]);

  useEffect(() => {
    setStartDate(filterDates.startDate);
    setEndDate(filterDates.endDate);
  }, []);

  const toggleAll = () => {
    setRange(!range);
  };

  const formatDate = (date: Date | null): string | undefined => {
    if (!date) return undefined;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);

  const handleDownloadAllClick = () => {
    const params = {
      format: formatSelect,
      authToken: token,
      downloadAll: true,
    };

    downloadVacancies(params);
  };

  const handleDownloadFilteredClick = async () => {
    setErrorMessage(null);
    if (errorTimeout) {
      clearTimeout(errorTimeout);
    }

    setErrorMessage(null);
    const params = {
      format: formatSelect,
      dateIntervalStart: startDateFormatted,
      dateIntervalEnd: endDateFormatted,
      authToken: token,
      downloadAll: false,
    };

    const result = await downloadVacancies(params);
    if (!result.success) {
      setErrorMessage("No vacancies for the selected period");
      const timeout = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setErrorTimeout(timeout);
    }
  };
  useEffect(() => {
    return () => {
      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }
    };
  }, [errorTimeout]);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={style.modal} onClick={handleClickOutside}>
      <div className={style.modalContent}>
        <div className={style.header_wrapper}>
          <h3>Export Applications</h3>
          <button onClick={onClose} className={style.closeButton}>
            <img src="/images/close.svg" alt="close modal window" />
          </button>
        </div>

        {hasVacancies === false ? (
          <div className={style.noVacanciesMessage}>
            <p>There are no applications yet</p>
          </div>
        ) : hasVacancies === null ? (
          <div className={style.loadingMessage}>
            <p>Loading...</p>
          </div>
        ) : (
          <>
            <div className={style.btn_wrapper}>
              <button
                onClick={() => setFormatSelect("xlsx")}
                className={`${style.btn} ${
                  formatSelect === "xlsx" ? style.selected_btn : ""
                }`}
              >
                Export as XLSX
              </button>
              <button
                onClick={() => setFormatSelect("csv")}
                className={`${style.btn} ${
                  formatSelect === "csv" ? style.selected_btn : ""
                }`}
              >
                Export as CSV
              </button>
            </div>

            <div className={style.time_all}>
              <p className={style.time_title}>
                Select the time period for viewing applications
              </p>
              <p className={style.time_text}>Export all</p>
              <button
                onClick={toggleAll}
                className={`${style.btn_all} ${
                  range ? style.selected_all : ""
                }`}
              ></button>
            </div>
            {!range ? (
              <div className={style.container_date}>
                <div className={style.datePicker}>
                  <label className={style.input_title}>From</label>
                  <div className={style.customDateInputWrapper}>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="DD.MM.YY"
                      className={style.customDateInput}
                      dateFormat="dd.MM.yy"
                    />
                    <img
                      src="/images/Calendar.svg"
                      alt="calendar icon"
                      className={style.calendarIcon}
                    />
                  </div>
                </div>
                <div className={style.datePicker}>
                  <label className={style.input_title}>To</label>
                  <div className={style.customDateInputWrapper}>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText="DD.MM.YY"
                      className={style.customDateInput}
                      dateFormat="dd.MM.yy"
                      minDate={startDate ?? undefined}
                    />
                    <img
                      src="/images/Calendar.svg"
                      alt="calendar icon"
                      className={style.calendarIcon}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {errorMessage && (
              <div className={`${style.errorMessage}`}>
                <p>{errorMessage}</p>
              </div>
            )}

            <Button
              text="Export"
              onClick={
                range ? handleDownloadAllClick : handleDownloadFilteredClick
              }
              color="blue"
              className={style.export_btn}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ExportModal;
