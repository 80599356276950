import { OfferCard } from "../OfferCard/OfferCard";
import style from "./OfferSection.module.css";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useNavigate } from "react-router-dom";

export const OfferSection = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const onCreate = async () => {
    if (!isAuthenticated) {
      navigate("/sign-in");
    } else {
      navigate("/select-subscription");
    }
  };
  return (
    <div className={style.wrapper}>
      <h2 className={style.title}>Try one-day free trial</h2>
      <p className={style.subtitle}>
        Try Raisly free for one day! Unlock all features to simplify your career
        development. Start your trial today and advance your professional
        future!
      </p>

      <div className={style.wrapper_cards}>
        <OfferCard
          saveCount={69}
          period="Year"
          priceOld={21.59}
          priceNow={9.0}
          colorBtn="blue"
          textBtn="Get started"
          onClick={onCreate}
        />
        <OfferCard
          saveCount={30}
          period="Monthly"
          priceOld={29.59}
          priceNow={19.99}
          colorBtn="white"
          textBtn="Get started"
          onClick={onCreate}
        />
      </div>
    </div>
  );
};
