import axios from "axios";

interface SupportModalParams {
  name?: string | undefined;
  email?: string | undefined;
  question: string;
  authToken: string;
}

export const sendSupportRequest = async (params: SupportModalParams) => {
  const { name, email, question, authToken } = params;

  const url = `https://raislyhq.com/api/support/`;

  const requestData = authToken
    ? { message: question, }
    : { full_name: name, email: email, message: question };

  const headers = authToken
    ? {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }
    : { "Content-Type": "application/json" };

  try {
    const response = await axios.post(url, requestData, { headers });
    console.log("Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
