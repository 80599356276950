import React, {FC} from 'react';

import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";

import styles from "./footer.module.css";

export const Footer: FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.leftInfo}>
                <LogoIcon />
                <p className={styles.copyright}>©Raisly {new Date().getFullYear()}, Inc</p>
            </div>
            <div className={styles.rightInfo}>
                <a target={"_blank"} href="/refund-and-cancellation-policy">Refund and cancellation policy</a>
                <a target={"_blank"} href="/privacy-policy">Privacy policy</a>
                <a target={"_blank"} href="/terms-of-use">Terms of use</a>
            </div>
        </footer>
    );
};