import React, { FC, useEffect, useState } from "react";

import { ForgotPasswordWrapper } from "../../components/ForgotPasswordWrapper/ForgotPasswordWrapper";
import { Button } from "../../components/Button/Button";

import { ReactComponent as EllipseIcon } from "../../assets/icons/ellipseIcon.svg";

import { useProfile } from "../../context/ProfileContext";

import styles from "./page.module.css";

export const ChangeSubscriptionPage: FC = () => {
  const { handleGetSubscriptionPlans, handleCreateSubscription } = useProfile();

  const [activePlan, setActivePlan] = useState<any>();
  const [subscriptionPlans, setSubscriptionPlans] = useState<any[]>([]);

  const getNextBillingDate = () => {
    const today = new Date();
    const nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
    const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
    return activePlan === 1
      ? nextYear.toLocaleDateString("ru-RU")
      : nextMonth.toLocaleDateString("ru-RU");
  };

  const fetchPlans = async () => {
    const data = await handleGetSubscriptionPlans();
    setActivePlan(data.subscription_plans[0]);
    setSubscriptionPlans(data.subscription_plans);
    console.log(data.subscription_plans);
  };

  const onCreate = async () => {
    const data = await handleCreateSubscription(activePlan.name);

    window.location.href = data.payment_url;
  };
  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <div className={styles.signInPage}>
      <ForgotPasswordWrapper pageTitle={"Choose your plan"}>
        <div className={styles.benefitsBox}>
          <h3 className={styles.benefitTitle}>Benefits</h3>
          <ul className={styles.list}>
            <li className={styles.list_item}>Apply for 150 jobs daily</li>
            <li className={styles.list_item}>Fast applying</li>
            <li className={styles.list_item}>
              Applying only to jobs relevant for you
            </li>
            <li className={styles.list_item}>
              Track your applications in dashboard
            </li>
          </ul>
        </div>
        <div className={styles.billingCycleWrapper}>
          <h3 className={styles.benefitTitle}>Billing cycle</h3>
          <div className={styles.cyclesList}>
            {!!subscriptionPlans.length &&
              subscriptionPlans.map((plan) => (
                <div
                  key={plan.name}
                  onClick={() => setActivePlan(plan)}
                  className={`${styles.cycleItem} ${
                    activePlan.name === plan.name && styles.active
                  }`}
                >
                  <h4>{plan.name}</h4>
                  <p className={styles.cyclePrice}>${plan.price} per month</p>
                  <span className={styles.discount}>
                    {plan.name === "Monthly" ? "Save 30%" : "Save 69%"}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.checkout}>
          <div className={styles.checkoutItem}>
            <h3>Total due</h3>
            <p className={styles.price}>$              {!!activePlan &&
                (activePlan?.name === "Monthly"
                    ? activePlan?.price
                    : activePlan?.price * 12)}</p>
          </div>
          <div className={styles.checkoutItem}>
            <h3 className={styles.rightTitle}>Next billing</h3>
            <p className={styles.futurePayment}>
              {new Date(
                new Date().setDate(new Date().getDate())
              ).toLocaleDateString("ru-RU")}{" "}
              <EllipseIcon /> $
              {!!activePlan &&
                (activePlan?.name === "Monthly"
                  ? activePlan?.price
                  : activePlan?.price * 12)}
            </p>
          </div>
        </div>
        <br />
        <br />
        <Button
          disabled={!activePlan}
          onClick={onCreate}
          color={"blue"}
          text={"Select subscription"}
        />
        <p className={styles.subText}>
          By clicking “Try one day for free” you agree to our{" "}
          <a href="/refund-and-cancellation-policy">
            Refund and cancellation policy
          </a>
          .
        </p>
      </ForgotPasswordWrapper>
    </div>
  );
};

export default ChangeSubscriptionPage;
