import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage/HomePage";

import {
  AUTH_PAGES_URL,
  AUTH_TAB_VALUES,
  AuthProvider,
  PASSWORD_RESTORE_PAGES_URL,
} from "./context/AuthContext";
import { SignInPage } from "./Pages/SignInPage/SignInPage";
import { SignUpPage } from "./Pages/SignUpPage/SignUpPage";
import { ConfirmMailPage } from "./Pages/ConfirmMailPage/ConfirmMailPage";
import { CreatePasswordPage } from "./Pages/CreatePasswordPage/CreatePasswordPage";
import { SetNamePage } from "./Pages/SetNamePage/SetNamePage";
import { TermsOfUsePage } from "./Pages/TermsOfUsePage/TermsOfUsePage";
import { PrivacyPolicyPage } from "./Pages/PrivacyPolicyPage/PrivacyPolicyPage";
import { RefundPage } from "./Pages/RefundPage/RefundPage";
import { ForgotPasswordPage } from "./Pages/ForgotPasswordPage/ForgotPasswordPage";
import { RestorePasswordPage } from "./Pages/RestorePasswordPage/RestorePasswordPage";
import { CheckYourMailPage } from "./Pages/CheckYourMailPage/CheckYourMailPage";
import { ProfilePage } from "./Pages/ProfilePage/ProfilePage";
import {
  PROFILE_PAGES_URL,
  SUBSCRIPTION_PAGES_URL,
} from "./context/ProfileContext";
import { CheckPasswordPage } from "./Pages/CheckPasswordPage/CheckPasswordPage";
import { ProfileRestorePasswordPage } from "./Pages/ProfileRestorePasswordPage/ProfileRestorePasswordPage";
import { ProvideInfoPage } from "./Pages/ProvideInfoPage/ProvideInfoPage";
import { SelectSubscriptionPage } from "./Pages/SelectSubscriptionPage/SelectSubscriptionPage";
import { SuccessPage } from "./Pages/SuccessPage/SuccessPage";
import { FailedPage } from "./Pages/FailedPage/FailedPage";
import { SubscriptionPage } from "./Pages/SubscriptionPage/SubscriptionPage";

import { Layout } from "./components/Layout/Layout";
import { NoHeaderFooterLayout } from "./components/NoHeaderFooterLayout/NoHeaderFooterLayout";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PrivateRouteDashboard from "./components/ProtectedRoute/ProtectedRoute";
import ChangeSubscriptionPage from "./Pages/ChangeSubscriptionPage/ChangeSubscriptionPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          element={<HomePage />}
        />

        <Route element={<PrivateRouteDashboard />}>
          <Route
            path="/dashboard"
            element={<NoHeaderFooterLayout />}
          >
            <Route
              index
              element={<Dashboard />}
            />
          </Route>
        </Route>

        <Route path={AUTH_TAB_VALUES.LOGIN}>
          <Route
            index
            element={<SignInPage />}
          />
        </Route>
        <Route path={AUTH_TAB_VALUES.REGISTER}>
          <Route
            index
            element={<SignUpPage />}
          />
        </Route>
        <Route path={AUTH_PAGES_URL.CONFIRM_EMAIL}>
          <Route
            index
            element={<ConfirmMailPage />}
          />
        </Route>
        <Route path={AUTH_PAGES_URL.CREATE_PASSWORD}>
          <Route
            index
            element={<CreatePasswordPage />}
          />
        </Route>
        <Route path={AUTH_PAGES_URL.ADD_YOUR_NAME}>
          <Route
            index
            element={<SetNamePage />}
          />
        </Route>
        <Route path={PASSWORD_RESTORE_PAGES_URL.FORGOT_PASSWORD}>
          <Route
            index
            element={<ForgotPasswordPage />}
          />
        </Route>
        <Route path={PASSWORD_RESTORE_PAGES_URL.SET_NEW_PASSWORD}>
          <Route
            index
            element={<RestorePasswordPage />}
          />
        </Route>
        <Route path={PASSWORD_RESTORE_PAGES_URL.CHECK_YOUR_MAIL}>
          <Route
            index
            element={<CheckYourMailPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path={PROFILE_PAGES_URL.PROFILE}
            element={<ProfilePage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path={PROFILE_PAGES_URL.CHECK_PASSWORD}
            element={<CheckPasswordPage />}
          />
        </Route>
        <Route path={PROFILE_PAGES_URL.RESET_PASSWORD}>
          <Route
            index
            element={<ProfileRestorePasswordPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path={PROFILE_PAGES_URL.PROVIDE_INFO}
            element={<ProvideInfoPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path={SUBSCRIPTION_PAGES_URL.SELECT_SUBSCRIPTION}
            element={<SelectSubscriptionPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
              path={SUBSCRIPTION_PAGES_URL.CHANGE_SUBSCRIPTION}
              element={<ChangeSubscriptionPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path={SUBSCRIPTION_PAGES_URL.SUBSCRIPTION}
            element={<SubscriptionPage />}
          />
        </Route>
        <Route path={"/success"}>
          <Route
            index
            element={<SuccessPage />}
          />
        </Route>
        <Route path={"/failed"}>
          <Route
            index
            element={<FailedPage />}
          />
        </Route>
        <Route path={"/terms-of-use"}>
          <Route
            index
            element={<TermsOfUsePage />}
          />
        </Route>
        <Route path={"/privacy-policy"}>
          <Route
            index
            element={<PrivacyPolicyPage />}
          />
        </Route>
        <Route path={"/refund-and-cancellation-policy"}>
          <Route
            index
            element={<RefundPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
