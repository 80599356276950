import React from "react";
import { ApplicationList } from "../../components/ApplicationList/ApplicationList";
import CustomCalendar from "../../components/Calendar/Calendar";
import { DashboardHeader } from "../../components/DashboardHeader/DashboardHeader";
import DonutChart from "../../components/DonutChart/DonutChart";
import { JobProvider } from "../../context/JobContext";
import style from "./Dashboard.module.css";

const DashboardContent: React.FC = () => {
  return (
    <div className="special-container">
      <DashboardHeader />
      <div className={style.wrapper}>
        <div className={style.left_wrapper}>
          <ApplicationList />
        </div>
        <div className={style.right_wrapper}>
          <CustomCalendar />
          <DonutChart />
        </div>
      </div>
    </div>
  );
};

export const Dashboard: React.FC = () => {
  return (
    <JobProvider>
      <DashboardContent />
    </JobProvider>
  );
};
