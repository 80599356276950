import React, {FC} from 'react';

import styles from "./authTabs.module.css";
import {AUTH_TAB_VALUES, useAuth} from "../../../context/AuthContext";
import {useNavigate} from "react-router";

interface AuthTabsProps {
    disableAction?: boolean
}

const AuthTabs: FC<AuthTabsProps> = ({ disableAction = false }) => {
    const navigate = useNavigate();
    const { activeTab, handleSetActiveTab } = useAuth();

    return (
        <div className={styles.tabsWrapper}>
            <div
                onClick={() => {
                    if (activeTab !== AUTH_TAB_VALUES.LOGIN && !disableAction) {
                        navigate(`/${AUTH_TAB_VALUES.LOGIN}`)
                        handleSetActiveTab(AUTH_TAB_VALUES.LOGIN)
                    }
                }}
                className={`${styles.tabItem} ${activeTab === AUTH_TAB_VALUES.LOGIN && styles.active}`
            }>
                Log in
            </div>
            <div
                onClick={() => {
                    if (activeTab !== AUTH_TAB_VALUES.REGISTER && !disableAction) {
                        navigate(`/${AUTH_TAB_VALUES.REGISTER}`)
                        handleSetActiveTab(AUTH_TAB_VALUES.REGISTER)
                    }
                }}
                className={`${styles.tabItem} ${activeTab === AUTH_TAB_VALUES.REGISTER && styles.active}`}
            >
                Sign up
            </div>
        </div>
    );
};

export default AuthTabs;