import {axiosPrivateInstance} from "../axiosConfig/axiosConfig";

export interface CreateResumeDTO {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "date": "2024-07-23",
    "position_name": { name: string }[];
    "experience": string
    "work_location": string
    "employment_options": string,
    "stay_country": string
    "stay_city": string
    "skills": { name: string }[],
    "education":         {
        "name": string
        "level": string
    }[]
    "language":         {
        "name": string
        "level": string
    }[]
    "another_works":         {
        "title": string
        "experience": string
    }[]
}

export async function getProfileData(): Promise<any> {
    const response = await axiosPrivateInstance.get(
        `/api/account/`
    );
    return response.data;
}

export async function getSubscription(): Promise<any> {
    const response = await axiosPrivateInstance.get(
        `/api/subscription/`
    );
    return response.data;
}

export async function getResume(): Promise<any> {
    const response = await axiosPrivateInstance.get(
        `/api/resume-list/`
    );
    return response.data;
}

export async function getSubscriptionPlans(): Promise<any> {
    const response = await axiosPrivateInstance.get(
        `/api/subscription-plans/`
    );
    return response.data;
}

export async function getResumeFile(id: string): Promise<any> {
    const response = await axiosPrivateInstance.get(
        `/api/resume-file/${id}/`
    );
    return response.data;
}


export async function createResumeFile(id: string, file: File): Promise<any> {
    const response = await axiosPrivateInstance.patch(
        `/api/resume-file/${id}/`, { file }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
    );
    return response.data;
}

export async function createResumeList(data: CreateResumeDTO): Promise<any> {
    const response = await axiosPrivateInstance.post(
        `/api/resume-list/`, data
    );
    return response.data;
}

export async function createSubscription(name: string): Promise<any> {
    const response = await axiosPrivateInstance.post(
        `/api/subscription-create/`, {name }
    );
    return response.data;
}

export async function deleteSubscription(message: string): Promise<any> {
    const response = await axiosPrivateInstance.post(
        `/api/subscription-delete/`, { message }
    );
    return response.data;
}

export async function updateEmail(email: string, password: string): Promise<any> {
    const response = await axiosPrivateInstance.put(
        `/api/account/email/change/`, {email, password}
    );
    return response.data;
}


export async function updatePassword(old_password: string, password: string): Promise<any> {
    const response = await axiosPrivateInstance.put(
        `/api/account/password/change/`, { old_password, password }
    );
    return response.data;
}

export async function updateFullName(full_name: string): Promise<any> {
    const response = await axiosPrivateInstance.patch(
        `/api/account/`, { full_name }
    );
    return response.data;
}

export async function updateResume(id: string, data: CreateResumeDTO): Promise<any> {
    const response = await axiosPrivateInstance.patch(
        `/api/resume/${id}/`, data
    );
    return response.data;
}