import React, {FC, useState} from 'react';
import {useNavigate} from "react-router";

import {Button} from "../../components/Button/Button";
import {InputWrapper} from "../../components/common/InputWrapper/InputWrapper";
import {ForgotPasswordWrapper} from "../../components/ForgotPasswordWrapper/ForgotPasswordWrapper";

import {PASSWORD_RESTORE_PAGES_URL, useAuth} from "../../context/AuthContext";
import {PROFILE_PAGES_URL} from "../../context/ProfileContext";

import styles from "./page.module.css";

export const CheckPasswordPage: FC = () => {
    const navigate = useNavigate();
    const { handleCheckPassword } = useAuth();

    const [error, setError] = useState({ hasError: false, message: "" });
    const [password, setPassword] = useState("");

    const onContinueClick = async () => {
        try {
            const data = await handleCheckPassword(password);

            if (data.is_correct_password) {
                navigate(`/${PROFILE_PAGES_URL.RESET_PASSWORD}?old_pass=${encodeURI(password)}`);
            } else {
                setError({ hasError: true, message: "Your credentials are incorrect" })
            }
        } catch (err) {

        }
    }

    const onSubActionClick = async () => {
        navigate(`/${PASSWORD_RESTORE_PAGES_URL.FORGOT_PASSWORD}`);

    }

    const handlePasswordChange = (value: string) => setPassword(value);

    return (
        <div className={styles.signInPage}>
            <ForgotPasswordWrapper pageTitle={"Enter your password"}>
                <InputWrapper
                    type={"password"}
                    title={"Enter your password"}
                    name={"password"}
                    id={"usr-password"}
                    value={password}
                    error={error}
                    placeholder={"Password"}
                    onChange={handlePasswordChange}
                />
                <br/>
                <br/>
                <Button disabled={password.length < 8} onClick={onContinueClick} color={"blue"} text={"Continue"} />
                <br/>
                <div onClick={onSubActionClick} className={styles.subAction}>
                    Forgot password?
                </div>
            </ForgotPasswordWrapper>
        </div>
    );
};