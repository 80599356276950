import { Link } from "react-router-dom";
import styles from "./CardItem.module.css";

type Props = {
  title: string;
  text: string;
};

export const CardItem: React.FC<Props> = ({ title, text }) => {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.wrapper_top}`}>
        <div>
          <Link
            to=""
            className={`${styles.arrow_btn}`}
          >
            <img
              src="/images/arrow.svg"
              alt=""
            />
          </Link>
        </div>
        <p  className={`${styles.title}`}>{title}</p >
      </div>
      <div className={`${styles.wrapper_bottom}`}>
        <span className={`${styles.text}`}>{text}</span>
      </div>
    </div>
  );
};
