import React, { FC, useEffect } from 'react';
import styles from "./page.module.css";
import {Button} from "../../components/Button/Button";
import {useNavigate, useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import { AUTH_PAGES_URL, AUTH_TAB_VALUES, useAuth } from '../../context/AuthContext';

export const CheckYourMailPage: FC = () => {
    const { handleLogoutClick } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/${AUTH_TAB_VALUES.LOGIN}`)
    }
    useEffect(() => {
        handleLogoutClick()
    }, []);

    return (
        <div className={styles.signInPage}>
            <div className={styles.authWrapper}>
                <div className={styles.topAction}>
                    <h1 className={styles.title}>{"Check your mail"}</h1>
                </div>
                <p className={styles.subtitle}>Password restore link has been sent to <span>{searchParams.get("email")}</span>.</p>
                <p className={`${styles.subtitle} ${styles.mb}`}>Please check your email and follow further steps.</p>
                <Button onClick={onClick} color={"blue"} text={"Go to login"} />
            </div>

        </div>
    );
};
