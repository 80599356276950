import React, {FC, PropsWithChildren, useState} from 'react';
import {useNavigate} from "react-router";

import {useAuth} from "../../context/AuthContext";
import backArrow from "../../assets/icons/backArrow.svg";

import styles from "./authWrapper.module.css";
import AuthTabs from "../common/AuthTabs/AuthTabs";
import {InputWrapper} from "../common/InputWrapper/InputWrapper";
import {Button} from "../Button/Button";

interface AuthWrapperProps {
    pageTitle: string;
    backButton?: boolean;
    disableTabAction?: boolean;
}

const AuthWrapper: FC<AuthWrapperProps & PropsWithChildren> = ({ pageTitle = "", backButton = false, disableTabAction = false,  children }) => {
    const navigate = useNavigate();

    const onBackButtonClick = () => navigate(-1)

    return (
        <div className={styles.authWrapper}>
            <div className={styles.topAction}>
                {backButton && (
                    <button className={styles.backButton} onClick={onBackButtonClick}>
                        <img src={backArrow} alt="Go Back"/>
                    </button>
                )}
                <h1 className={styles.title}>{pageTitle}</h1>
            </div>
            <AuthTabs disableAction={disableTabAction} />
            {children}
        </div>
    );
};

export default AuthWrapper;