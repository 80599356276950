import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';

import reportWebVitals from './reportWebVitals';

import {AuthProvider as AuthContextProvider} from "./context/AuthContext";
import {ProfileProvider} from "./context/ProfileContext";

import { refreshApi } from "./utils/refresh";

import App from './App';

import './index.css';
import {DialogProvider} from "./context/DialogContext";

export const store = createStore({
    refresh: refreshApi,
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <AuthProvider store={store}>
          <AuthContextProvider>
              <ProfileProvider>
                  <DialogProvider>
                    <App />
                  </DialogProvider>
              </ProfileProvider>
          </AuthContextProvider>
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
