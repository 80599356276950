import { useNavigate } from "react-router";
import { Button } from "../Button/Button";
import style from "./DashboardSection.module.css";

export const DashboardSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <h1 className={style.title}>Unlock your career growth with Raisly</h1>
      <p className={style.subtitle}>
        Your personal career companion. Whether you're searching for a new job,
        preparing for a big interview, or looking to grow at your current place
        of work, we provide the tools and support you need to take the next step
        in your career.
      </p>
      <div className={style.button}>
        <Button
          onClick={() => navigate("/dashboard")}
          color="blue"
          text="Let’s get started"
        />
      </div>
      <img
        className={style.image}
        src="./images/dashboard.png"
        alt="dashboard"
      />
    </>
  );
};
