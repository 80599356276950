import React, {
  FC,
  HTMLInputTypeAttribute,
  useState,
  KeyboardEvent,
} from 'react';

import { ReactComponent as ShowPasswordIcon } from '../../../assets/icons/eyeClosed.svg';
import { ReactComponent as HidePasswordIcon } from '../../../assets/icons/activeEye.svg';

import styles from './inputWrapper.module.css';

interface InputErrorProps {
  message: string;
  hasError: boolean;
}

interface InputWrapperProps {
  name: string;
  id: string;
  value: string;
  title: string;
  placeholder: string;
  error?: InputErrorProps;
  type: HTMLInputTypeAttribute;
  disableShowPassword?: boolean;
  onChange?: (val: string) => void;
  classLabel?: string;
  isDisabled?: boolean
}

export const InputWrapper: FC<InputWrapperProps> = ({
                                                      name,
                                                      type,
                                                      placeholder,
                                                      id,
                                                      onChange,
                                                      value,
                                                      title,
                                                      classLabel,
                                                      disableShowPassword = false,
                                                      error = {message: '', hasError: false},
                                                      isDisabled = false,
                                                    }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <label className={classLabel ? classLabel : styles.label} htmlFor={id}>
        {title}
      </label>
      <div className={styles.inputWrapper}>
        <input
          disabled={isDisabled}
          id={id}
          type={showPassword && type === 'password' ? 'text' : type}
          name={name}
          value={value}
          className={`${styles.input} ${error.hasError && styles.error}`}
          placeholder={placeholder}
          onChange={onHandleChange}
          required
        />
        {type === 'password' && (
          <div
            onClick={handleToggleShowPassword}
            className={`${styles.svgWrapper} ${!!value && styles.activeEye}`}
          >
            {!disableShowPassword &&
              (showPassword ? <HidePasswordIcon/> : <ShowPasswordIcon/>)}
          </div>
        )}
      </div>
      {error.hasError && <p className={styles.errorMessage}>{error.message}</p>}
    </div>
  );
};
