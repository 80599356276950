import styles from './AboutCardItem.module.css'

type Props = {
  title: string;
  text: string;
};

export const AboutCardItem: React.FC<Props> = ({ title, text }) => {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.wrapper_top}`}>
       
        <p className={`${styles.title}`}>{title}</p>
      </div>
      <div className={`${styles.wrapper_bottom}`}>
        <span className={`${styles.text}`}>{text}</span>
      </div>
    </div>
  );
};
