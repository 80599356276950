import React, { FC, useEffect, useState, useRef } from "react";

import styles from "./page.module.css";
import AuthWrapper from "../../components/AuthWrapper/AuthWrapper";
import { InputWrapper } from "../../components/common/InputWrapper/InputWrapper";
import { Button } from "../../components/Button/Button";
import { AUTH_TAB_VALUES, useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import { DEFAULT_ERROR } from "../../constants/error";

export const SignInPage: FC = () => {
  const navigate = useNavigate();
  const { activeTab, handleSetActiveTab, handleLoginClick } = useAuth();

  const [error, setError] = useState<any>(DEFAULT_ERROR);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleForgotPasswordPageNavigate = () => navigate("/forgot-password");

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const onLoginClick = async () => {
    const data = await handleLoginClick(email, password);

    // @ts-ignore
    if (data?.hasError) {
      setError(data);
    } else {
      navigate("/dashboard");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      buttonRef.current?.click();
    }
  };

  useEffect(() => {
    handleSetActiveTab(AUTH_TAB_VALUES.LOGIN);
  }, []);

  return (
    <div className={styles.signInPage}>
      <AuthWrapper pageTitle={"Welcome back!"}>
        <InputWrapper
          type={"email"}
          title={"Email"}
          name={"email"}
          id={"usr-email"}
          value={email}
          error={error}
          placeholder={"example@gmail.com"}
          onChange={handleEmailChange}
        />
        <InputWrapper
          type={"password"}
          title={"Password"}
          name={"password"}
          id={"usr-password"}
          value={password}
          placeholder={"Enter your password here"}
          onChange={handlePasswordChange}
        />
        <p
          className={styles.subaction}
          onClick={handleForgotPasswordPageNavigate}
        >
          Forgot password?
        </p>
        <Button
          disabled={!email || !password}
          onClick={onLoginClick}
          color={"blue"}
          text={"Log in"}
        />
      </AuthWrapper>
    </div>
  );
};
