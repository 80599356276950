import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { getSubscription } from "../../service/profile/profile";

const PrivateRouteDashboard: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [subscription, setSubscription] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      if (isAuthenticated) {
        try {
          const data = await getSubscription();
          console.log("API Response:", data.subscription.is_active);
          if (data.subscription.is_active === true) {
            setCheck(true);
          } else {
            setCheck(false);
          }
        } catch (error) {
          console.error("Error fetching subscription:", error);
          setCheck(false);
        }
      }
      setLoading(false);
    };

    checkSubscription();
  }, [isAuthenticated]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }

  if (!check) {
    return <Navigate to="/select-subscription" />;
  }

  return <Outlet />;
};

export default PrivateRouteDashboard;
