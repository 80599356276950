import React, {FC, useEffect, useState} from 'react';

import {useNavigate} from "react-router";

import {Button} from "../../components/Button/Button";
import AuthWrapper from "../../components/AuthWrapper/AuthWrapper";
import {InputWrapper} from "../../components/common/InputWrapper/InputWrapper";

import {AUTH_PAGES_URL, AUTH_TAB_VALUES, useAuth} from "../../context/AuthContext";

import styles from "./page.module.css";
import {validateEmail} from "../../utils/validationUtil";

export const SignUpPage: FC = () => {
    const navigate = useNavigate();
    const { handleSetActiveTab, handleRegisterEmail }= useAuth();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState({ hasError: false, message: "" });

    const handleEmailChange = (value: string) => {
        setEmail(value)
        if (!validateEmail(value)) {
            setEmailError({ hasError: true, message: "You must provide an email address" });
        } else {
            setEmailError({ hasError: false, message: "" });
        }
    }

    const onSignUpClick = async () => {
        setLoading(true);
        const data = await handleRegisterEmail(email);

        if (data?.hasError) {
            setEmailError(data)
        } else {
            navigate(`/${AUTH_PAGES_URL.CONFIRM_EMAIL}?email=${encodeURI(email)}`);
        }
        setLoading(false);
    }

    useEffect(() => {
        handleSetActiveTab(AUTH_TAB_VALUES.REGISTER)
    }, []);

    return (
        <div className={styles.signInPage}>
            <AuthWrapper pageTitle={"Welcome to Rasily!"}>
                <InputWrapper
                    type={"email"}
                    title={"Email"}
                    name={"email"}
                    id={"usr-email"}
                    value={email}
                    error={emailError}
                    placeholder={"example@gmail.com"}
                    onChange={handleEmailChange}
                />
                <p className={styles.divider}></p>
                <Button disabled={!validateEmail(email) || loading} onClick={onSignUpClick} color={"blue"} text={"Sign up"} />
                <p className={styles.termsText}>
                    By clicking “Sign up” you agree to our <a target={"_blank"} href={"/terms-of-use"} className={styles.link}>Terms of Use</a> and <a target={"_blank"} href={"/privacy-policy"} className={styles.link}>Privacy policy</a>
                </p>
            </AuthWrapper>
        </div>
    );
};
