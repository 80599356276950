import React, {FC, useState} from 'react';

import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";

import {Button} from "../Button/Button";
import {useDialog} from "../../context/DialogContext";

import styles from "./styles.module.css";
import {useProfile} from "../../context/ProfileContext";

const CancelSubscriptionDialog: FC = () => {
    const { handleDeleteSubscription } = useProfile();
    const { closeCancelSubDialog, cancelSubDialogOpen } = useDialog();

    const [reason, setReason] = useState("1");

    const [additionalInfo, setAdditionalInfo] = useState("");

    const onCancelClick = async () => {
        const message = reason + ". " + additionalInfo
        const data = await handleDeleteSubscription(message);

        if (!data.hasError) {
            window.location.pathname = "/profile"
        }
    }

    if (!cancelSubDialogOpen) {
        return null
    }

    return (
        <>
            <div className={styles.backdrop}></div>
            <div className={cancelSubDialogOpen ? styles.openDialog : styles.closedDialog}>
                <div className={styles.header}>
                    <h3 className={styles.title}>Tell us why you want to cancel your plan?</h3>
                    <CloseIcon onClick={closeCancelSubDialog} />
                </div>
                <div className={styles.list}>
                    <div className={styles.listItem}>
                        <input onChange={(e) => setReason(e.target.value)} value={"Successfully secured employment."} id={"1"} name={"reason"} type="radio"/>
                        <label htmlFor="1">Successfully secured employment.</label>
                    </div>
                    <div className={styles.listItem}>
                        <input
                            onChange={(e) => setReason(e.target.value)}
                            id={"2"}
                            name={"reason"}
                            type="radio"
                            value={"Unhappy with job matches or platform features."}
                        />
                        <label htmlFor="2">Unhappy with job matches or platform features.</label>
                    </div>
                    <div className={styles.listItem}>
                        <input value={"Subscription fee is too high."} onChange={(e) => setReason(e.target.value)} id={"3"} name={"reason"} type="radio"/>
                        <label htmlFor="3">Subscription fee is too high.</label>
                    </div>
                    <div className={styles.listItem}>
                        <input value={"Changed to another plan."} onChange={(e) => setReason(e.target.value)} id={"4"} name={"reason"} type="radio"/>
                        <label htmlFor="4">Changed to another plan.</label>
                    </div>
                    <div className={styles.listItem}>
                        <input value={"Other"} onChange={(e) => setReason(e.target.value)} id={"5"} name={"reason"} type="radio"/>
                        <label htmlFor="5">Other</label>
                    </div>
                </div>
                <div className={styles.textAreaWrapprer}>
                    <p className={styles.text}>
                        Give us some additional info why you want to receive a refund
                    </p>
                    <textarea
                        name="Additional Info"
                        placeholder={"Type here additional info"}
                        id="6"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        cols={30}
                        rows={10}></textarea>
                </div>
                <br/>
                <Button text={"Cancel plan"} color={"blue"} onClick={onCancelClick} />
            </div>
        </>
    );
};

export default CancelSubscriptionDialog;