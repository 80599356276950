import React, { useState } from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import styles from "./header.module.css";
import { Button } from "../Button/Button";
import { useLocation, useNavigate } from "react-router";
import { useScroll } from "../../context/ScrollContext";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { SupportModal } from "../SupportModal/SupportModal";

interface HeaderProps {
  scrollToAbout?: () => void;
  scrollToPlans?: () => void;
  scrollToHowItWorks?: () => void;
}
export const Header: React.FC<HeaderProps> = ({
  scrollToAbout,
  scrollToPlans,
  scrollToHowItWorks,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);

  const { token } = useAuth();

  const handleClickOpenModal = () => {
    setModalOpen(true);
  };

  const handleClickCloseModal = () => {
    setModalOpen(false);
  };

  const handleScroll = async (scrollFunction?: () => void) => {
    if (scrollFunction && typeof scrollFunction === "function") {
      if (location.pathname !== "/") {
        navigate("/", { replace: true });

        scrollFunction();
      } else {
        scrollFunction();
      }
    } else {
      console.error("scrollFunction is not a function");
    }
  };

  return (
    <header className={styles.header}>
      <Link to={"/"}>
        <LogoIcon />
      </Link>
      <nav className={styles.centralNav}>
        <div onClick={() => handleScroll(scrollToHowItWorks!)}>
          How it works
        </div>
        <div onClick={() => handleScroll(scrollToAbout!)}>About us</div>
        <div onClick={() => handleScroll(scrollToPlans!)}>Plans</div>
        <div onClick={handleClickOpenModal}>Contact us</div>
      </nav>
      {!useIsAuthenticated() ? (
        <div className={styles.rightActionButtons}>
          <Button
            onClick={() => navigate("/sign-up")}
            color={"white"}
            text={"Sign up"}
          />
          <Button
            onClick={() => navigate("/sign-in")}
            color={"blue"}
            text={"Log in"}
          />
        </div>
      ) : (
        <div className={styles.goDashboard}>
          <Button
            onClick={() => navigate("/dashboard")}
            color={"blue"}
            text={"Go to dashboard"}
          />
        </div>
      )}

      {isModalOpen && (
        <SupportModal
          onClose={handleClickCloseModal}
          authToken={token}
        />
      )}
    </header>
  );
};
