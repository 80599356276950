import React from "react";

import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";

import styles from "./page.module.css";

export const RefundPage = () => {
  return (
    <>
      <Header
        scrollToAbout={() => {}}
        scrollToPlans={() => {}}
        scrollToHowItWorks={() => {}}
      />
      <main className={styles.pageWrapper}>
        <h1 className={styles.pageTitle}>Refund and cancellation policy</h1>
        <p className={styles.publication}>Publication date 24.06.2024</p>

        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>1. General Policy</h2>
          <p className={styles.paragraph}>
            You can cancel any subscription within 24 hours of subscribing
            without any loss.
          </p>
        </div>

        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>2. Monthly</h2>
          <ul className={styles.pageList}>
            <li>
              If you cancel your monthly subscription, we will cancel any future
              payments, and you’ll still have access to the service until the
              end of the paid period.
            </li>
            <li>
              We do not grant refunds for monthly subscriptions, you can still
              request for a refund
            </li>
            <li>
              While refunds are not typically provided, you may still enquire
              for it, and we will review your request on a case-by-case basis.
            </li>
          </ul>
          <p className={styles.paragraph}>
            Our Privacy Policy is available on a separate page. Our Privacy
            Policy explains to you how we process information about you. You
            shall understand that through your use of the website you
            acknowledge the processing of this information shall be undertaken
            in accordance with the Privacy Policy.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>3. Annual</h2>
          <h3 className={styles.subtitle}>3.1 Refund Calculation</h3>
          <ul className={styles.pageList}>
            <li>
              If you need to cancel your annual subscription, we will calculate
              your refund based on how long you have used the service.
            </li>
            <li>
              For example, if the monthly subscription costs $19 and the annual
              subscription costs $169 (instead of $228), the price per month in
              the annual plan is $14.08. If you cancel annual plan, we will
              subtract the number of months you’ve used multiplied by the
              regular monthly subscription price from the amount you paid.
              Here’s the formula we use: Refund Amount = Annual Subscription
              Price - (Months Used x Monthly Subscription Price)
            </li>
          </ul>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            3.2 Maximum Usage for Refund Eligibility
          </h3>
          <ul className={styles.pageList}>
            <li>
              You can request a refund if you have used the service for 8 months
              or less. This is because the total value of the service you
              received exceeds or matches the amount you originally paid for the
              annual subscription.
            </li>
            <li>
              If you have used the service for more than 8 months, no refund
              will be issued as the total value of the service provided exceeds
              the amount paid for the annual subscription.
            </li>
          </ul>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            3.3 Access Until Cancellation Month-End
          </h3>
          <p className={styles.paragraph}>
            Upon cancellation, regardless of refund eligibility, you will
            continue to have access to the service until the end of the current
            month of cancellation.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};
