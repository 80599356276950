import React from "react";
import { useJobContext } from "../../context/JobContext";
import style from "./Pagination.module.css";

export const Pagination = () => {
  const { currentPage, totalPages, setCurrentPage } = useJobContext();

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const disabledLeft = currentPage === 1;
  const disabledRight = currentPage === totalPages;
  return (
    <div className={style.wrapper}>
      <button
        className={style.btn}
        onClick={handlePreviousPage}
        disabled={disabledLeft}
      >
        <div className={style.btn_wrapper}></div>
        <img
          src="/images/left.svg"
          alt=""
          className={disabledLeft ? style.disabled_left : ""}
        />
      </button>
      <span className={style.num_page}>
        {currentPage}-{totalPages}
      </span>
      <button
        className={style.btn}
        onClick={handleNextPage}
        disabled={disabledRight}
      >
        <img
          src="/images/right.svg"
          alt=""
          className={disabledRight ? style.disabled_right : ""}
        />
      </button>
    </div>
  );
};
