import React, { useState, useEffect } from "react";
import { Button } from "../Button/Button";
import style from "./SupportModal.module.css";
import { sendSupportRequest } from "../../service/support/support";
import { useProfile } from "../../context/ProfileContext";
import { InputWrapper } from "../common/InputWrapper/InputWrapper";

interface WarningModalProps {
  onClose: () => void;
  authToken?: string;
}

export const SupportModal: React.FC<WarningModalProps> = ({
  onClose,
  authToken,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");

  const { profileData } = useProfile();

  useEffect(() => {
    if (profileData) {
      setEmail(profileData.email);
      setName(profileData.fullName);
    }
  }, [profileData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await sendSupportRequest({
        name: authToken ? profileData?.fullName : name,
        email: authToken ? profileData?.email : email,
        question,
        authToken: authToken || "",
      });
      onClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleNameChange = (value: string) => {
    setName(value);
  };
  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={style.modal}
      onClick={handleClickOutside}
    >
      <div className={style.modalContent}>
        <div className={style.header_wrapper}>
          <h3 className={style.title}>Tell us your question</h3>
          <button
            onClick={onClose}
            className={style.closeButton}
          >
            <img
              src="/images/close.svg"
              alt="close modal window"
            />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <>
            <InputWrapper
              type={"text"}
              title={"Tell us your name"}
              name={"name"}
              id={"usr-name"}
              value={name}
              placeholder={"John Doe"}
              onChange={handleNameChange}
              classLabel={style.labelText}
            />
            <InputWrapper
              type={"email"}
              title={"Enter your email"}
              name={"email"}
              id={"usr-email"}
              value={email}
              placeholder={"example@gmail.com"}
              onChange={handleEmailChange}
              classLabel={style.labelText}
            />
          </>

          <label htmlFor="question">
            <p className={style.labelTextArea}>How can we help you?</p>

            <textarea
              id="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className={style.textArea}
              placeholder="Type here additional info"
              required
            />
          </label>
          <Button
            color="blue"
            text="Let’s talk"
            className={style.button}
            onClick={() => handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};
