import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from "react-router";

import {Button} from "../../components/Button/Button";
import {InputWrapper} from "../../components/common/InputWrapper/InputWrapper";
import {ForgotPasswordWrapper} from "../../components/ForgotPasswordWrapper/ForgotPasswordWrapper";

import { ReactComponent as RightArrowIcon } from "../../assets/icons/rightArrow.svg"

import {useDialog} from "../../context/DialogContext";
import {PROFILE_PAGES_URL, SUBSCRIPTION_PAGES_URL, useProfile} from "../../context/ProfileContext";

import {DEFAULT_ERROR} from "../../constants/error";
import {validateEmail} from "../../utils/validationUtil";

import styles from "./page.module.css";

export const ProfilePage: FC = () => {
    const navigate = useNavigate();

    const { openLogoutDialog, openResumeDialog } = useDialog();

    const {
        profileData,
        handleUpdateEmail,
        handleGetProfileData,
        handleUpdateFullName,
    } = useProfile();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState(DEFAULT_ERROR);
    const [nameError, setNameError] = useState(DEFAULT_ERROR);

    const handlePasswordChange = (value: string) => {
        setPassword(value);
        setEmailError(DEFAULT_ERROR)
    }

    const handleNameChange = (value: string) => {
        setName(value);
        setNameError(DEFAULT_ERROR)

    }
    const handleEmailChange = (value: string) => {
        setEmail(value);
        setEmailError(DEFAULT_ERROR)
    }

    const handleCancelChange = () => {
        setEmail(profileData?.email as string)
        setName(profileData?.fullName as string)
    }

    const handleChangePasswordClick = () => {
        navigate(`/${PROFILE_PAGES_URL.CHECK_PASSWORD}`)
    }

    const handleDataUpdateClick = async () => {
        if (email !== profileData?.email) {
            if ((password.length >= 8 && validateEmail(email))) {
                const data = await handleUpdateEmail(password, email)
                setPassword("");
                if (data?.hasError) {
                    setEmailError(data)
                }
            } else {
                setEmailError({ hasError: true, message: "You need to provide your password and updated email address" })
            }
        }
        if (name !== profileData?.fullName) {
            if (name.length >= 4) {
                const data = await handleUpdateFullName(name);
                if (data?.hasError) {
                    setNameError(data)
                }
            } else {
                setNameError({ hasError: true, message: "Your full name should contain at least 4 symbols" })
            }
        }
    }

    const onSubscriptionClick = () => {
        navigate(`/${SUBSCRIPTION_PAGES_URL.SUBSCRIPTION}`)
    }

    useEffect(() => {
        if (profileData) {
            setEmail(profileData.email)
            setName(profileData.fullName)
        }
    }, [profileData]);

    useEffect(() => {
        handleGetProfileData();
    }, []);

    return (
        <div className={styles.signInPage}>
            <ForgotPasswordWrapper pageTitle={"Profile"}>
                <InputWrapper
                    type={"text"}
                    title={"Name"}
                    name={"name"}
                    id={"usr-name"}
                    value={name}
                    error={nameError}
                    placeholder={"John Doe"}
                    onChange={handleNameChange}
                />
                <InputWrapper
                    type={"email"}
                    title={"Email"}
                    name={"email"}
                    id={"usr-email"}
                    value={email}
                    error={emailError}
                    placeholder={"example@gmail.com"}
                    onChange={handleEmailChange}
                />
                {(profileData && profileData.email !== email) && (
                    <InputWrapper
                        type={"password"}
                        title={"Password"}
                        name={"password"}
                        id={"usr-password"}
                        value={password}
                        error={emailError}
                        placeholder={"Enter your password here"}
                        onChange={handlePasswordChange}
                    />
                )}
                <br/>
                <div className={styles.primaryActions}>
                    <Button onClick={handleDataUpdateClick} disabled={!!profileData && ((profileData.email === email) && profileData.fullName === name)} color={"blue"} text={"Save changes"} />
                    <button onClick={handleCancelChange} className={styles.cancelBtn}>Discard Changes</button>
                </div>
                <div className={styles.subActionsList}>
                    <div onClick={handleChangePasswordClick} className={styles.subAction}>
                        <p>Change password</p>
                        <RightArrowIcon />
                    </div>
                    <div onClick={openResumeDialog} className={styles.subAction}>
                        <p>Change my data</p>
                        <RightArrowIcon />
                    </div>
                </div>
                <div className={styles.secondaryActions}>
                    <Button onClick={onSubscriptionClick} color={"white"} text={"Subscription"} />
                    <button onClick={openLogoutDialog} className={styles.logoutButton}>Log out</button>
                </div>
            </ForgotPasswordWrapper>
        </div>
    );
};