import React, { FC, useState } from 'react';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import useSignIn from "react-auth-kit/hooks/useSignIn";

import { Button } from "../../components/Button/Button";
import { InputWrapper } from "../../components/common/InputWrapper/InputWrapper";
import { ForgotPasswordWrapper } from "../../components/ForgotPasswordWrapper/ForgotPasswordWrapper";

import { AUTH_TAB_VALUES } from "../../context/AuthContext";
import {PROFILE_PAGES_URL, useProfile} from "../../context/ProfileContext";

import { validatePasswordStrength, checkPasswordRequirements } from "../../utils/validationUtil";

import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CheckErrorIcon } from "../../assets/icons/error.svg";

import styles from "./page.module.css";

export const ProfileRestorePasswordPage: FC = () => {
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { handleUpdatePassword } = useProfile();

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [passwordError, setPasswordError] = useState({ hasError: false, message: "" });
    const [repeatPasswordError, setRepeatPasswordError] = useState({ hasError: false, message: "" });

    const requirements = checkPasswordRequirements(password);

    const handlePasswordChange = (value: string) => {
        setPassword(value);
        if (!validatePasswordStrength(value)) {
            setPasswordError({ hasError: true, message: "Password does not meet complexity requirements" });
        } else {
            setPasswordError({ hasError: false, message: "" });
        }
    };

    const handleRepeatPasswordChange = (value: string) => {
        setRepeatPassword(value);
        if (value !== password) {
            setRepeatPasswordError({ hasError: true, message: "Passwords do not match" });
        } else {
            setRepeatPasswordError({ hasError: false, message: "" });
        }
    };

    const onCreatePasswordClick = async () => {
        if (validatePasswordStrength(password) && searchParams.get("old_pass")) {
            const oldPass = decodeURI(searchParams.get("old_pass") as string);
            const data = await handleUpdatePassword(oldPass, password);

            if (data?.hasError) {
                setPasswordError(data);
                return;
            }

            if (data?.access) {
                signIn({
                    auth: {
                        token: data.access,
                        type: "Bearer"
                    },
                    refresh: data.refresh,
                })
                navigate(`/${PROFILE_PAGES_URL.DASHBOARD}`);
            } else {
                navigate(`/${AUTH_TAB_VALUES.LOGIN}`);
            }


        } else {
            setPasswordError({ hasError: true, message: "Something went wrong" });
        }
    };

    return (
        <div className={styles.signInPage}>
            <ForgotPasswordWrapper pageTitle={"Enter your new password"}>
                <InputWrapper
                    type={"password"}
                    title={"Enter your password"}
                    name={"password"}
                    id={"usr-password"}
                    value={password}
                    error={passwordError}
                    placeholder={"Enter your password here"}
                    onChange={handlePasswordChange}
                />
                <div className={styles.info}>
                    <p className={styles.infoTitle}>Must contain at least</p>
                    <ul className={styles.infoList}>
                        <li className={styles.infoListItem}>
                            {requirements.length ? <CheckIcon className={styles.icon} /> : <CheckErrorIcon className={styles.iconError} />}
                            At least 8 characters
                        </li>
                        <li className={styles.infoListItem}>
                            {requirements.uppercase ? <CheckIcon className={styles.icon} /> : <CheckErrorIcon className={styles.iconError} />}
                            At least 1 upper case character
                        </li>
                        <li className={styles.infoListItem}>
                            {requirements.specialChar ? <CheckIcon className={styles.icon} /> : <CheckErrorIcon className={styles.iconError} />}
                            At least 1 special character
                        </li>
                    </ul>
                </div>
                <InputWrapper
                    type={"password"}
                    title={"Repeat your password"}
                    name={"repeat-password"}
                    id={"usr-repeat-password"}
                    value={repeatPassword}
                    error={repeatPasswordError}
                    placeholder={"Repeat your password here"}
                    onChange={handleRepeatPasswordChange}
                />
                <br />
                <br />
                <Button disabled={repeatPasswordError.hasError || passwordError.hasError || repeatPassword.length < 8} onClick={onCreatePasswordClick} color={"blue"} text={"Continue"} />
            </ForgotPasswordWrapper>
        </div>
    );
};