import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDropzone } from "react-dropzone";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import { InputWrapper } from "../../components/common/InputWrapper/InputWrapper";
import { ForgotPasswordWrapper } from "../../components/ForgotPasswordWrapper/ForgotPasswordWrapper";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import SkillsInput, { Skill } from "../../components/SkillsInput/SkillsInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CitySelect from "../../components/CitySelect/CitySelect";

import styles from "./page.module.css";
import {
  SUBSCRIPTION_PAGES_URL,
  useProfile,
} from "../../context/ProfileContext";
import style from "../../components/EditInfoModal/EditInfoModal.module.css";

const TYPES_OF_WORK = [
  { label: "Full-time", value: "Full-time" },
  { label: "Part-time", value: "Part-time" },
];

const DEGREES = [
  { label: "Bachelor's", value: "Bachelor's" },
  { label: "Master's", value: "Master's" },
];

const PROFICIENCIES = [
  { label: "A1", value: "A1" },
  { label: "A2", value: "A2" },
  { label: "B1", value: "B1" },
  { label: "B2", value: "B2" },
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
];

const PLACE_OF_WORK = [
  { label: "Office", value: "Office" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Remote", value: "Remote" },
];

export const ProvideInfoPage = () => {
  const navigate = useNavigate();

  const { handleCreateResumeFile, handleCreateResume } = useProfile();

  const [activeStep, setActiveStep] = useState(1);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [saved, setSeved] = useState<string | null>(null);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      position_name: "",
      experience: "",
      employment_options: "",
      work_location: "",
      stay_city: "",
      stay_country: "",
      education: [] as { name: string; level: string }[],
      language: [] as { name: string; level: string }[],
      another_works: [] as { title: string; experience: string }[],
    },
  });

  const {
    fields: educationFields,
    append: addEducation,
    remove: removeEducation,
  } = useFieldArray({
    control,
    name: "education",
  });

  const {
    fields: languageFields,
    append: addLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control,
    name: "language",
  });

  const {
    fields: experienceFields,
    append: addExperience,
    remove: removeExperience,
  } = useFieldArray({
    control,
    name: "another_works",
  });

  const onDrop = (acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];
    if (selectedFile.size > 10 * 1024 * 1024) {
      setFileError("File size should be less than 10MB");
      setFile(null);
    } else {
      setFile(selectedFile);
      setFileError(null);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    maxSize: 10 * 1024 * 1024,
  });

  const onSubmit = async (data: any) => {
    const [city, country] = data.stay_city.split("/");
    const requestData = {
      ...data,
      stay_city: city,
      stay_country: country,
      position_name: [{ name: data.position_name }],
      skills: skills.map((skill) => ({ name: skill.name })),
    };
    const resumeData = await handleCreateResume(requestData);

    if (file && resumeData) {
      const fileData = await handleCreateResumeFile(resumeData.id, file);
    }
    setSeved("Saved");
    navigate(`/${SUBSCRIPTION_PAGES_URL.SELECT_SUBSCRIPTION}`);
  };

  const onSkip = () => {
    navigate(`/${SUBSCRIPTION_PAGES_URL.SELECT_SUBSCRIPTION}`);
  };

  const Step1 = () => (
    <>
      <div className={styles.inputsWrapper}>
        <Controller
          control={control}
          name="position_name"
          render={({ field }) => (
            <InputWrapper
              type={"text"}
              title={"Your desired position*"}
              // @ts-ignore
              name={"position_name"}
              id={"usr-position"}
              placeholder={"Front-end developer"}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="experience"
          render={({ field }) => (
            <InputWrapper
              type={"text"}
              title={"Years of experience*"}
              // @ts-ignore
              name={"experience"}
              id={"usr-experience"}
              placeholder={"1-2"}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.inputsWrapper}>
        <Controller
          control={control}
          name="employment_options"
          render={({ field }) => (
            <CustomSelect
              label={"Type of work*"}
              placeholder={"Full time"}
              options={TYPES_OF_WORK}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="work_location"
          render={({ field }) => (
            <CustomSelect
              label={"Place*"}
              placeholder={"Hybrid"}
              options={PLACE_OF_WORK}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.inputsWrapper}>
        <Controller
          control={control}
          name="stay_city"
          render={({ field }) => <CitySelect {...field} />}
        />
      </div>
    </>
  );

  // @ts-ignore
  const Step2 = () => (
    <>
      <SkillsInput
        skills={skills}
        setSkills={setSkills}
      />
      <div className={styles.infoBlock}>
        <div className={styles.infoBlockLabel}>
          <p>Add education</p>
          <PlusIcon onClick={() => addEducation({ name: "", level: "" })} />
        </div>
        {educationFields.map((item, index) => (
          <div
            key={item.id}
            className={styles.infoBlockGeneral}
          >
            <div className={styles.infoInputWrapper}>
              <Controller
                control={control}
                name={`education.${index}.name`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Major*"}
                    // @ts-ignore
                    name={`education.${index}.name`}
                    id={`edu-major-${item.id}`}
                    placeholder={"Major"}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`education.${index}.level`}
                render={({ field }) => (
                  <CustomSelect
                    label={"Degree*"}
                    placeholder={"Bachelor's"}
                    options={DEGREES}
                    {...field}
                  />
                )}
              />
            </div>
            <DeleteIcon onClick={() => removeEducation(index)} />
          </div>
        ))}
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.infoBlockLabel}>
          <p>Add language</p>
          <PlusIcon onClick={() => addLanguage({ name: "", level: "" })} />
        </div>
        {languageFields.map((item, index) => (
          <div
            key={item.id}
            className={styles.infoBlockGeneral}
          >
            <div className={styles.infoInputWrapper}>
              <Controller
                control={control}
                name={`language.${index}.name`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Language*"}
                    // @ts-ignore
                    name={`language.${index}.name`}
                    id={`lang-name-${item.id}`}
                    placeholder={"Language"}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`language.${index}.level`}
                render={({ field }) => (
                  <CustomSelect
                    label={"Proficiency*"}
                    placeholder={"B2"}
                    options={PROFICIENCIES}
                    {...field}
                  />
                )}
              />
            </div>
            <DeleteIcon onClick={() => removeLanguage(index)} />
          </div>
        ))}
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.infoBlockLabel}>
          <p>Add your previous experience</p>
          <PlusIcon
            onClick={() => addExperience({ title: "", experience: "" })}
          />
        </div>
        {experienceFields.map((item, index: number) => (
          <div
            key={item.id}
            className={styles.infoBlockGeneral}
          >
            <div className={styles.infoInputWrapper}>
              <Controller
                control={control}
                name={`another_works.${index}.title`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Position*"}
                    id={`exp-position-${item.id}`}
                    placeholder={"Position"}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`another_works.${index}.experience`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Years of experience*"}
                    id={`exp-years-${item.id}`}
                    placeholder={"1-2"}
                    {...field}
                  />
                )}
              />
            </div>
            <DeleteIcon onClick={() => removeExperience(index)} />
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <div
        onClick={onSkip}
        className={styles.skipButton}
      >
        Skip
      </div>
      <div className={styles.signInPage}>
      
        <ForgotPasswordWrapper
          backButton
          backButtonCallback={
            activeStep === 2 ? () => setActiveStep(1) : undefined
          }
          pageTitle={"Provide some info about you"}
        >
          
          <div className={styles.pageStepWrapper}>
            <div
              className={`${styles.pageStep} ${
                activeStep === 1 && styles.active
              }`}
            >
              1
            </div>
            <div
              className={`${styles.pageStep} ${
                activeStep === 2 && styles.active
              }`}
            >
              2
            </div>
          </div>
          <div className={styles.inputWrapper}>
            
            <div
              {...getRootProps()}
              className={styles.dropzone}
            >
              <input {...getInputProps()} />
              {/* {saved ? <p className={styles.saved}>{saved}</p> : null} */}
              
              {isDragActive ? (
                <p className={styles.active}>Drop the files here ...</p>
              ) : (
                <div>
                  <h2 className={styles.title}>Your CV</h2>
                  <p className={styles.inactive}>
                    Drag here or{" "}
                    <span className={styles.link}>open folder</span> to upload
                    new CV
                  </p>
                  {file && (
                    <div className={styles.fileInfo}>
                      <span className={styles.fileName}>{file.name}</span>
                      <img
                        src="/images/cv.svg"
                        alt="file icon"
                        className={styles.fileIcon}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {fileError && <p className={styles.fileError}>{fileError}</p>}
          </div>
          {activeStep === 1 ? <Step1 /> : <Step2 />}
          <Button
            onClick={
              activeStep === 1 ? () => setActiveStep(2) : handleSubmit(onSubmit)
            }
            color={"blue"}
            text={"Continue"}
          />
        </ForgotPasswordWrapper>
      </div>
    </>
  );
};
