import React from "react";
import { useDialog } from "../../context/DialogContext";
import {Button} from "../Button/Button";
import {useAuth} from "../../context/AuthContext";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";

import './LogoutDialog.css';

const LogoutDialog: React.FC = () => {
    const { handleLogoutClick } = useAuth();
    const { logoutDialogOpen, closeLogoutDialog } = useDialog();

    if (!logoutDialogOpen) return null;

    const handleLogout = () => {
        closeLogoutDialog();
        handleLogoutClick();
        window.location.pathname = "/sign-in";
    };

    return (
        <>
            <div className="backdrop" onClick={closeLogoutDialog}></div>
            <div className="logout-dialog">
                <button className="close-button" onClick={closeLogoutDialog}><CloseIcon /></button>
                <h2>Are you sure that you want to log out?</h2>
                <div className="content">
                    <p>You can log in at any time just using your email and password.</p>
                    <div className="buttons">
                        <Button color={"white"} onClick={closeLogoutDialog} text={"Cancel"} />
                        <Button color={"blue"} onClick={handleLogout} text={"Log out"} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogoutDialog;
