import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getPasswordResetLink,
  login,
  passwordCheck,
  pinCheck,
  registerEmail,
  registerFinish,
  updatePassword,
} from "../service/auth/auth";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import useSignOut from 'react-auth-kit/hooks/useSignOut';

interface AuthContextInterface {
  error: any;
  loading: boolean;
  activeTab: AUTH_TAB_VALUES;
    handleLogoutClick: () => any;
  token: string ;
  handlePinCheck: (pin: string) => any;
  handleRegisterEmail: (email: string) => any;
  handleCheckPassword: (password: string) => any;
  handleGetEmailResetLink: (email: string) => any;
  handleUpdatePassword: (pin: number, password: string) => Promise<any>;
  handleSetActiveTab: (value: AUTH_TAB_VALUES) => void;
  handleLoginClick: (email: string, password: string) => void;
  handleAccountVerify: (
    full_name: string,
    password: string,
    pin: number
  ) => Promise<any>;
}

export enum AUTH_TAB_VALUES {
  LOGIN = "sign-in",
  REGISTER = "sign-up",
}

export enum AUTH_PAGES_URL {
  CONFIRM_EMAIL = "confirm-email",
  CREATE_PASSWORD = "create-password",
  ADD_YOUR_NAME = "add-your-name",
}

export enum PASSWORD_RESTORE_PAGES_URL {
  FORGOT_PASSWORD = "forgot-password",
  SET_NEW_PASSWORD = "password-reset",
  CHECK_YOUR_MAIL = "check-your-mail",
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const signOut = useSignOut();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(AUTH_TAB_VALUES.LOGIN);
  const [token, setToken] = useState<string >('');

  const signIn = useSignIn();

  const handleSetActiveTab = (value: AUTH_TAB_VALUES) => {
    return setActiveTab(value);
  };

  const handlePinCheck = async (pin: string) => {
    setLoading(true);
    try {
      const data = await pinCheck(pin);
    } catch (error: any) {
      console.log(error);
      return { hasError: true, message: error.response.data.detail };
    }
    setLoading(false);
  };

  const handleLoginClick = async (email: string, password: string) => {
    setLoading(true);
    try {
      const data = await login({ email, password });
      signIn({
        auth: {
          token: data.access,
          type: "Bearer",
        },
        refresh: data.refresh,
      });
      setToken(data.access);
      localStorage.setItem("authToken", data.access);  // Save token
    } catch (error: any) {
      console.log(error);
      return { hasError: true, message: error.response.data.detail };
    }
    setLoading(false);
  };

    const handleLogoutClick = () => {
        setLoading(true);
        try {
          signOut();
          localStorage.removeItem("authToken"); // delate token
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
        setLoading(false);
    }

  const handleRegisterEmail = async (email: string) => {
    setLoading(true);
    try {
      return await registerEmail({ email });
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      return { hasError: true, message: error.response.data.detail };
    }
  };

  const handleAccountVerify = async (
    full_name: string,
    password: string,
    pin: number
  ) => {
    setLoading(true);
    try {
      return await registerFinish({ full_name, password, pin });
    } catch (error: any) {
      setLoading(false);
      return { hasError: true, message: error.response.data.detail };
    }
  };

  const handleGetEmailResetLink = async (email: string) => {
    setLoading(true);
    try {
      return await getPasswordResetLink(email);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      return { hasError: true, message: error.response.data.detail };
    }
  };

  const handleCheckPassword = async (password: string) => {
    setLoading(true);
    try {
      return await passwordCheck(password);
    } catch (error: any) {
      console.log(error);
      return { hasError: true, message: error.response.data.detail };
    }
  };

  const handleUpdatePassword = async (pin: number, password: string) => {
    setLoading(true);
    try {
      const data = await updatePassword(pin, password);
      setLoading(false);
      return data;
    } catch (error: any) {
      setLoading(false);
      return { hasError: true, message: error.response.data.detail };
    }
  };
  return (
    <AuthContext.Provider
      value={{
        error,
        loading,
        activeTab,
        token,
        handlePinCheck,
        handleLoginClick,
        handleSetActiveTab,
                handleLogoutClick,
        handleRegisterEmail,
        handleAccountVerify,
        handleCheckPassword,
        handleUpdatePassword,
        handleGetEmailResetLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
