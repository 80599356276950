import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {
    CreateResumeDTO, createResumeFile, createResumeList,
    createSubscription, deleteSubscription,
    getProfileData, getResume, getResumeFile, getSubscription,
    getSubscriptionPlans,
    updateEmail,
    updateFullName,
    updatePassword, updateResume
} from "../service/profile/profile";

export interface ProfileData {
    "fullName": string;
    "email": string
}

interface ProfileContextInterface {
    loading: boolean;
    handleGetResume: () => Promise<any>;
    handleUpdateResume: (id: string, data: CreateResumeDTO) => Promise<any>;
    handleUpdateFullName: (fullName: string) => Promise<any>;
    handleUpdateEmail: (password: string, email: string) => Promise<any>;
    handleUpdatePassword: (oldPassword: string, password: string) => Promise<any>;
    profileData: ProfileData | null,
    handleGetSubscription: () => Promise<any>;
    handleGetProfileData: () => Promise<any>;
    handleCreateResume: (data: CreateResumeDTO) => Promise<any>;
    handleGetResumeFile: (id: string) => Promise<any>;
    handleCreateResumeFile: (id: string, file: File) => Promise<any>;
    handleGetSubscriptionPlans: () => Promise<any>;
    handleCreateSubscription: (name: string) => Promise<any>;
    handleDeleteSubscription: (message: string) => Promise<any>;

}

export enum AUTH_TAB_VALUES {
    LOGIN = "sign-in",
    REGISTER = "sign-up"
}

export enum PROFILE_PAGES_URL {
    PROFILE = "profile",
    DASHBOARD = "dashboard",
    PROVIDE_INFO = "provide-info",
    CHECK_PASSWORD = "profile/check-password",
    RESET_PASSWORD = "profile/reset-password",
}

export enum SUBSCRIPTION_PAGES_URL {
    SUBSCRIPTION = "subscription",
    SELECT_SUBSCRIPTION = "select-subscription",
    CHANGE_SUBSCRIPTION = "change-subscription",
}

const ProfileContext = createContext<ProfileContextInterface | undefined>(undefined);

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState(false);

    const [subscription, setSubscription] = useState<any>(null);
    const [profileData, setProfileData] = useState<ProfileData | null>(null);

    const handleGetProfileData = async () => {
        setLoading(true);
        try {
            const data = await getProfileData()
            setProfileData({ fullName: data.account.full_name, email: data.account.email })
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
        setLoading(false);
    }

    const handleGetSubscription = async () => {
        try {
            const data = await getSubscription();
            setSubscription(data);
            return data;
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleGetResume = async () => {
        try {
            return await getResume()
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleCreateResume = async (data: CreateResumeDTO) => {
        setLoading(true);
        try {
            setLoading(false);
            return await createResumeList(data)
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleCreateResumeFile = async (id: string, file: File) => {
        setLoading(true);
        try {
            setLoading(false);
            return await createResumeFile(id, file)
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleGetResumeFile = async (id: string) => {
        setLoading(true);
        try {
            setLoading(false);
            return await getResumeFile(id)
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleGetSubscriptionPlans = async () => {
        setLoading(true);
        try {
            setLoading(false);
            return await getSubscriptionPlans()
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
        setLoading(false);
    }

    const handleCreateSubscription = async (name: string) => {
        setLoading(true);
        try {
            setLoading(false);
            return await createSubscription(name)
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleDeleteSubscription = async (message: string) => {
        setLoading(true);
        try {
            setLoading(false);
            return await deleteSubscription(message)
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleUpdateEmail = async (password: string, email: string) => {
        setLoading(true);
        try {
            const data = await updateEmail(email, password)
            setProfileData(prevState => ({ ...prevState, email } as ProfileData))
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
        setLoading(false);
    }

    const handleUpdateFullName = async (fullName: string) => {
        setLoading(true);
        try {
            const data = await updateFullName(fullName);
            setProfileData({ fullName: data.full_name, email: data.email })
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
        setLoading(false);
    }

    const handleUpdateResume = async (id: string, data: CreateResumeDTO) => {
        setLoading(true);
        try {
            setLoading(false);
            const resume = await updateResume(id, data);
            return resume
        } catch (error: any) {
            console.log(error)
            return { hasError: true, message: error.response.data.detail }
        }
    }

    const handleUpdatePassword = async (oldPassword: string, password: string) => {
        setLoading(true);
        try {
            const data = await updatePassword(oldPassword, password);
            setLoading(false);
            return data;
        } catch (error: any) {
            setLoading(false);
            return { hasError: true, message: error.response.data.detail }
        }
    }

    useEffect(() => {
        handleGetProfileData();
    }, []);

    return (
        <ProfileContext.Provider
            value={{
                loading,
                profileData,
                handleGetResume,
                handleUpdateEmail,
                handleUpdateResume,
                handleUpdateFullName,
                handleGetProfileData,
                handleUpdatePassword,
                handleGetSubscription,
                handleCreateResume,
                handleGetResumeFile,
                handleCreateResumeFile,
                handleGetSubscriptionPlans,
                handleCreateSubscription,
                handleDeleteSubscription,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = (): ProfileContextInterface => {
    const context = useContext(ProfileContext);
    if (context === undefined) {
        throw new Error('useProfile must be used within an ProfileProvider');
    }
    return context;
};