
import { getRefresh } from "../service/auth/auth";
import createRefresh from "react-auth-kit/createRefresh";

export const refreshApi = createRefresh({
    interval: 60, // Refresh the token in every 30 minutes
    // @ts-ignore
    refreshApiCallback: async () => {
        try {
            const data = await getRefresh();
            return {
                isSuccess: true,
                newAuthToken: data.access,
                newAuthTokenExpireIn: 720,
                newRefreshTokenExpiresIn: 1440,
            };
        } catch (error) {
            console.error(error);
            return {
                isSuccess: false,
            };
        }
    }
});