import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";

import {AuthCode} from "../../components/common/AuthCode/AuthCode";
import {Button} from "../../components/Button/Button";
import AuthWrapper from "../../components/AuthWrapper/AuthWrapper";

import {AUTH_PAGES_URL, AUTH_TAB_VALUES, useAuth} from "../../context/AuthContext";

import styles from "./page.module.css";
import {useNavigate} from "react-router";

export const ConfirmMailPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { handleSetActiveTab, handlePinCheck, handleGetEmailResetLink }= useAuth();

    const [email, setEmail] = useState("");
    const [timer, setTimer] = useState(60);
    const [error, setError] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [code, setCode] = useState(["", "", "", "", ""])

    const handleResendCode = async () => {
        if (email) {
            const data = await handleGetEmailResetLink(email);

            if (data.hasError) {
                setError(true);
            } else {
                setCodeSent(true);
                setTimer(60);
            }
        }
    };

    const handleCodeSet = (val: string[]) => {
        setCode(val);
        setError(false);
    }

    const handleActionClick = async () => {
        const data = await handlePinCheck(code.join(""));

        if (data?.hasError) {
            setError(true)
        } else {
            navigate(`/${AUTH_PAGES_URL.CREATE_PASSWORD}?pin=${code.join("")}`)
        }
    }

    useEffect(() => {
        if (searchParams.get("email")) {
            setEmail(searchParams.get("email") as string);
        }
    }, [searchParams]);

    useEffect(() => {
        handleSetActiveTab(AUTH_TAB_VALUES.REGISTER)
    }, []);

    useEffect(() => {
        let interval: any;
        if (codeSent && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setCodeSent(false);
            setTimer(60);
        }
        return () => clearInterval(interval);
    }, [codeSent, timer]);

    return (
        <div className={styles.signInPage}>
            <AuthWrapper backButton disableTabAction pageTitle={"Welcome to Rasily!"}>
                <h2 className={styles.subtitle}>Confirm your email</h2>
                <p className={styles.paragraph}>Email sent to <span>{email}</span></p>
                <AuthCode hasError={error} code={code} onChange={handleCodeSet} />
                <Button onClick={handleActionClick} disabled={code.includes("")} color={"blue"} text={"Confirm email"} />
                <p className={styles.subText}>Didn’t receive code? {" "}
                    {!codeSent ? (
                        <span onClick={handleResendCode} className={styles.sendAgain}>Send again</span>
                    ) : (
                        <span className={styles.codeSent}>Send again in {timer === 60 ? "1:00" : timer}</span>
                    )}
                </p>
            </AuthWrapper>
        </div>
    );
};
