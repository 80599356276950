import React, {FC, useRef} from 'react';

import { ReactComponent as AlertIcon } from "../../../assets/icons/allert.svg"

import styles from "./authCode.module.css";

interface AuthCodeProps {
    code: string[];
    hasError: boolean;
    onChange: (code: string[]) => void;
}

export const AuthCode: FC<AuthCodeProps> = ({ code, onChange, hasError = false }) => {
    const inputsRef = useRef([]);
    const handleChange = (e: any, index: number) => {
        const newCode = [...code];
        newCode[index] = e.target.value.slice(-1);
        onChange(newCode);

        if (!!e.target.value && index < 4 ) {
            // @ts-ignore
            inputsRef.current[index + 1]?.focus();
        } else if (!e.target.value) {
            // @ts-ignore
            inputsRef.current[index - 1]?.focus();
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={`${styles.codeWrapper} ${hasError && styles.error}`}>
                {code.map((num, index) => {
                    // @ts-ignore
                    return (<input ref={el => inputsRef.current[index] = el}
                                   key={index}
                                   id={`code-input-${index}`}
                                   type="text"
                                   maxLength={1}
                                   value={num}
                                   className={styles.codeInput}
                                   onChange={(e) => handleChange(e, index)}
                        />
                    )})}
            </div>
            {hasError && (
                <div className={styles.errorMessage}>
                    <AlertIcon />
                    <p>Wrong code</p>
                </div>
            )}
        </div>

    );
};