import React, {useState} from 'react';

import {Button} from "../../components/Button/Button";
import AuthWrapper from "../../components/AuthWrapper/AuthWrapper";
import {InputWrapper} from "../../components/common/InputWrapper/InputWrapper";

import { validatePasswordStrength, checkPasswordRequirements } from "../../utils/validationUtil";

import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CheckErrorIcon } from "../../assets/icons/error.svg";

import styles from "./page.module.css";
import {useNavigate} from "react-router";
import {AUTH_PAGES_URL} from "../../context/AuthContext";
import {useSearchParams} from "react-router-dom";

export const CreatePasswordPage = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [passwordError, setPasswordError] = useState({ hasError: false, message: "" });
    const [repeatPasswordError, setRepeatPasswordError] = useState({ hasError: false, message: "" });

    const requirements = checkPasswordRequirements(password);

    const handlePasswordChange = (value: string) => {
        setPassword(value);
        if (!validatePasswordStrength(value)) {
            setPasswordError({ hasError: true, message: "Password does not meet complexity requirements" });
        } else {
            setPasswordError({ hasError: false, message: "" });
        }
    };

    const handleRepeatPasswordChange = (value: string) => {
        setRepeatPassword(value);
        if (value !== password) {
            setRepeatPasswordError({ hasError: true, message: "Passwords do not match" });
        } else {
            setRepeatPasswordError({ hasError: false, message: "" });
        }
    };

    const onCreatePasswordClick = async () => {
        navigate(`/${AUTH_PAGES_URL.ADD_YOUR_NAME}?pin=${searchParams.get("pin")}&password=${password}`)
    }

    return (
        <div className={styles.signInPage}>
            <AuthWrapper disableTabAction backButton pageTitle={"Welcome to Rasily!"}>
                <h2 className={styles.subtitle}>Create a password</h2>
                <InputWrapper
                    type={"password"}
                    title={"Enter your password"}
                    name={"password"}
                    id={"usr-password"}
                    value={password}
                    error={passwordError}
                    placeholder={"Enter your password here"}
                    onChange={handlePasswordChange}
                />
                <div className={styles.info}>
                    <p className={styles.infoTitle}>Must contain at least</p>
                    <ul className={styles.infoList}>
                        <li className={styles.infoListItem}>
                            {requirements.length ? <CheckIcon className={styles.icon} /> : <CheckErrorIcon className={styles.iconError} />}
                            At least 8 characters
                        </li>
                        <li className={styles.infoListItem}>
                            {requirements.uppercase ? <CheckIcon className={styles.icon} /> : <CheckErrorIcon className={styles.iconError} />}
                            At least 1 upper case character
                        </li>
                        <li className={styles.infoListItem}>
                            {requirements.specialChar ? <CheckIcon className={styles.icon} /> : <CheckErrorIcon className={styles.iconError} />}
                            At least 1 special character
                        </li>
                    </ul>
                </div>
                <InputWrapper
                    type={"password"}
                    title={"Repeat your password"}
                    name={"repeat-password"}
                    id={"usr-repeat-password"}
                    value={repeatPassword}
                    disableShowPassword
                    error={repeatPasswordError}
                    placeholder={"Repeat your password here"}
                    onChange={handleRepeatPasswordChange}
                />
                <p className={styles.divider}></p>
                <Button disabled={repeatPasswordError.hasError || passwordError.hasError || repeatPassword.length < 8} onClick={onCreatePasswordClick} color={"blue"} text={"Continue"} />
            </AuthWrapper>
        </div>
    );
};
