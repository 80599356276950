import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend, Title } from "chart.js";
import { useJobContext } from "../../context/JobContext";
import style from "./DonutChart.module.css";

ChartJS.register(ArcElement, Legend, Title);

const DonutChart: React.FC = () => {
  const max = 150;
  const { currentDayJobCount } = useJobContext();
  const total = currentDayJobCount;
  const borderRadiusValue = total < max ? 50 : 0;
  const data = {
    datasets: [
      {
        data: [total, max - total],
        backgroundColor: ["#2C64E3", "rgba(255, 58, 238, 0)"],
        borderRadius: borderRadiusValue,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: 73,
  };

  return (
    <div className={style.wrapper}>
      <div className={style.text_wrapper}>
        <h2 className={style.title}>Number of your applications</h2>
        <p className={style.subtitle}>
          Apply for 150 applications daily to get your offer quickly
        </p>
      </div>
      <div className={style.dunut_wrapper}>
        <div className={style.back}></div>
        <div className={style.count}>
          <p>
            {total}/{max}
          </p>
        </div>
        <div className={style.dunut_count}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
