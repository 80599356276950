import React, {useState} from 'react';
import styles from "../CreatePasswordPage/page.module.css";
import AuthWrapper from "../../components/AuthWrapper/AuthWrapper";
import {InputWrapper} from "../../components/common/InputWrapper/InputWrapper";
import {Button} from "../../components/Button/Button";
import {useSearchParams} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {PROFILE_PAGES_URL} from "../../context/ProfileContext";

export const SetNamePage = () => {
    const signIn = useSignIn()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { handleAccountVerify } = useAuth();

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState({ hasError: false, message: "" });

    const handleNameChange = (value: string) => {
        setName(value);
        if (value.length < 4) {
            setNameError({ hasError: true, message: "Name should contain at least 4 symbols" })
        } else {
            setNameError({ hasError: false, message: "" })
        }
    }

    const onActionClick = async () => {
        const pin = searchParams.get("pin");
        const password = searchParams.get("password");
        if (pin && password) {
            const data = await handleAccountVerify(name, password, +pin);

            if (data?.hasError) {
                setNameError(data);

                return;
            }

            signIn({
                auth: {
                    token: data.access,
                    type: "Bearer"
                },
                refresh: data.refresh,
            })

            navigate(`/${PROFILE_PAGES_URL.PROVIDE_INFO}`)
        } else {
            setNameError({ hasError: true, message: "You had to provide password and verification code" })
        }
    }

    return (
        <div className={styles.signInPage}>
            <AuthWrapper disableTabAction backButton pageTitle={"Welcome to Rasily!"}>
                <h2 className={styles.subtitle}>Tell us your name</h2>
                <InputWrapper
                    type={"text"}
                    title={"Enter your name"}
                    name={"name"}
                    id={"usr-name"}
                    value={name}
                    error={nameError}
                    placeholder={"Jack London"}
                    onChange={handleNameChange}
                />
                <p className={styles.divider}></p>
                <Button disabled={name.length < 3} onClick={onActionClick} color={"blue"} text={"Continue"} />
            </AuthWrapper>
        </div>
    );
};
