import React from 'react';

import styles from "./page.module.css";
import {Button} from "../../components/Button/Button";
import {useNavigate} from "react-router";
import {SUBSCRIPTION_PAGES_URL} from "../../context/ProfileContext";

export const FailedPage = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.signInPage}>
            <div className={styles.infoBlock}>
                <h1>Payment failed</h1>
                <br/>
                <br/>
                <Button onClick={() => navigate(`/${SUBSCRIPTION_PAGES_URL.SELECT_SUBSCRIPTION}`)} color={"blue"} text={"Go to plans"} />
                <br/>
                <Button onClick={() => navigate("/")} color={"white"} text={"Go to main screen"} />
            </div>
        </div>
    );
};