import {axiosInstance, axiosPrivateInstance} from "../axiosConfig/axiosConfig";

export interface LoginInterface {
    email: string;
    password: string;
}

export interface RegisterInterface {
    email: string;
}

export interface RegisterFinishInterface {
    full_name: string;
    password: string;
    pin: number;
}


export async function getRefresh(): Promise<any> {
    const response = await axiosInstance.get(
        `/api/token/refresh`,
    );
    return response.data;
}

export async function pinCheck(pin: string): Promise<any> {
    const response = await axiosInstance.get(
        `/api/account/check-pin/${pin}`,
    );
    return response.data;
}


export async function login(data: LoginInterface): Promise<any> {
    const response = await axiosInstance.post(
        `/api/token/`, data
    );
    return response.data;
}

export async function registerEmail(data: RegisterInterface): Promise<any> {
    const response = await axiosInstance.post(
        `/api/account/register/`, data
    );
    return response.data;
}

export async function registerFinish(data: RegisterFinishInterface): Promise<any> {
    const response = await axiosInstance.post(
        `/api/account/verify/`, data
    );
    return response.data;
}

export async function getPasswordResetLink(email: string): Promise<any> {
    const response = await axiosInstance.post(
        `/api/account/password/reset/`, { email }
    );
    return response.data;
}

export async function updatePassword(pin: number, password: string): Promise<any> {
    const response = await axiosInstance.post(
        `/api/account/password/change/`, { pin, password }
    );
    return response.data;
}

export async function passwordCheck(password: string): Promise<any> {
    const response = await axiosPrivateInstance.post(
        `/api/account/password/check/`, { password }
    );
    return response.data;
}