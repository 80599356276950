import React, { FC, useState, useEffect } from "react";
import { useProfile } from "../../context/ProfileContext";
import SkillsInput, { Skill } from "../SkillsInput/SkillsInput";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { InputWrapper } from "../common/InputWrapper/InputWrapper";
import CustomSelect from "../CustomSelect/CustomSelect";
import CitySelect from "../CitySelect/CitySelect";
import { useDialog } from "../../context/DialogContext";

import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { Button } from "../Button/Button";

import styles from "./styles.module.css";

import { createResumeList } from "../../service/profile/profile";

import Cookies from "js-cookie";

const TYPES_OF_WORK = [
  { label: "Full-time", value: "Full-time" },
  { label: "Part-time", value: "Part-time" },
];

const DEGREES = [
  { label: "Bachelor's", value: "Bachelor's" },
  { label: "Master's", value: "Master's" },
];

const PROFICIENCIES = [
  { label: "A1", value: "A1" },
  { label: "A2", value: "A2" },
  { label: "B1", value: "B1" },
  { label: "B2", value: "B2" },
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
];

const PLACE_OF_WORK = [
  { label: "Office", value: "Office" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Remote", value: "Remote" },
];

const EditResumeDialog: FC = () => {
  const { closeResumeDialog, resumeDialogOpen } = useDialog();

  const {
    handleCreateResumeFile,
    handleGetResume,
    handleUpdateResume,
    handleGetResumeFile,
  } = useProfile();

  const [resumeData, setResumeData] = useState<any>();
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [skills, setSkills] = useState<Skill[]>([]);

  const [saved, setSeved] = useState<string | null>(null);

  //TODO 
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      position_name: "",
      experience: "",
      employment_options: "",
      work_location: "",
      stay_city: "",
      stay_country: "-",
      education: [] as { name: string; level: string }[],
      language: [] as { name: string; level: string }[],
      another_works: [] as { title: string; experience: string }[],
    },
  });

  const {
    fields: educationFields,
    append: addEducation,
    remove: removeEducation,
  } = useFieldArray({
    control,
    name: "education",
  });

  const {
    fields: languageFields,
    append: addLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control,
    name: "language",
  });

  const {
    fields: experienceFields,
    append: addExperience,
    remove: removeExperience,
  } = useFieldArray({
    control,
    name: "another_works",
  });

  useEffect(() => {
    if (resumeDialogOpen) {
      const fetchData = async () => {
        const resumeData = await handleGetResume();
        let resumeFile;
        if (resumeData?.resumes?.length) {
          resumeFile = await handleGetResumeFile(resumeData.resumes[0]?.id);
        }

        if (resumeData?.resumes?.length) {
          setResumeData(resumeData.resumes[0]);
          console.log(resumeData.resumes[0]);

          const {
            position_name,
            experience,
            employment_options,
            work_location,
            stay_city,
            stay_country,
            skills,
            education,
            language,
            another_works,
          } = resumeData.resumes[0];

          setSkills(skills || []);
          reset({
            position_name: position_name[0]?.name || "",
            experience: experience || "",
            employment_options: employment_options || "",
            work_location: work_location || "",
            stay_city: stay_city || "",
            stay_country: stay_country || "",
            education: education || [],
            language: language || [],
            another_works: another_works || [],
          });
        }

        if (resumeFile) {
          setFile(file);
        }
      };
      fetchData();
    }
  }, [resumeDialogOpen, handleGetResume, reset]);

  const onDrop = (acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];
    if (selectedFile.size > 10 * 1024 * 1024) {
      setFileError("File size should be less than 10MB");
      setFile(null);
    } else {
      setFile(selectedFile);
      setFileError(null);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    maxSize: 10 * 1024 * 1024,
  });

  const onSubmit = async (data: any) => {
    if (resumeData && data) {

    }
    const [city, country] = data.stay_city.split("/");
    const requestData = {
      ...data,
      stay_city: city,
      stay_country: country,
      position_name: [{ name: data.position_name }],
      skills: skills.map((skill) => ({ name: skill.name })),
    };
    let updatedData
    if (resumeData?.id){
       updatedData = await handleUpdateResume(resumeData.id, requestData);
    } else {
      updatedData = await createResumeList(requestData);
    }
    
    Cookies.set("resumeUpdateStatus", "updated", { expires: 2 / 86400 });

    setTimeout(() => {
      Cookies.remove("resumeUpdateStatus");
    }, 2000);

    if (file && updatedData && resumeData?.id) {
     await handleCreateResumeFile(resumeData.id, file);

    }
    closeResumeDialog();
  };

  if (!resumeDialogOpen) return null;

  const Step1 = () => (
    <>
      <div className={styles.inputsWrapper}>
        <Controller
          control={control}
          name="position_name"
          render={({ field }) => (
            <InputWrapper
              type={"text"}
              title={"Your desired position"}
              // @ts-ignore
              name={"position_name"}
              id={"usr-position"}
              placeholder={"Front-end developer"}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="experience"
          render={({ field }) => (
            <InputWrapper
              type={"text"}
              title={"Years of experience"}
              // @ts-ignore
              name={"experience"}
              id={"usr-experience"}
              placeholder={"1-2"}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.inputsWrapper}>
        <Controller
          control={control}
          name="employment_options"
          render={({ field }) => (
            <CustomSelect
              label={"Type of work"}
              placeholder={"Full time"}
              options={TYPES_OF_WORK}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="work_location"
          render={({ field }) => (
            <CustomSelect
              label={"Place"}
              placeholder={"Hybrid"}
              options={PLACE_OF_WORK}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.inputsWrapper}>
        <Controller
          control={control}
          name="stay_city"
          render={({ field }) => <CitySelect {...field} />}
        />
      </div>
    </>
  );

  const Step2 = () => (
    <>
      <SkillsInput
        skills={skills}
        setSkills={setSkills}
      />
      <div className={styles.infoBlock}>
        <div className={styles.infoBlockLabel}>
          <p>Add education</p>
          <PlusIcon onClick={() => addEducation({ name: "", level: "" })} />
        </div>
        {educationFields.map((item, index) => (
          <div
            key={item.id}
            className={styles.infoBlockGeneral}
          >
            <div className={styles.infoInputWrapper}>
              <Controller
                control={control}
                name={`education.${index}.name`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Major"}
                    // @ts-ignore
                    name={`education.${index}.name`}
                    id={`edu-major-${item.id}`}
                    placeholder={"Major"}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`education.${index}.level`}
                render={({ field }) => (
                  <CustomSelect
                    label={"Degree"}
                    placeholder={"Bachelor's"}
                    options={DEGREES}
                    {...field}
                  />
                )}
              />
            </div>
            <DeleteIcon onClick={() => removeEducation(index)} />
          </div>
        ))}
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.infoBlockLabel}>
          <p>Add language</p>
          <PlusIcon onClick={() => addLanguage({ name: "", level: "" })} />
        </div>
        {languageFields.map((item, index) => (
          <div
            key={item.id}
            className={styles.infoBlockGeneral}
          >
            <div className={styles.infoInputWrapper}>
              <Controller
                control={control}
                name={`language.${index}.name`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Language"}
                    // @ts-ignore
                    name={`language.${index}.name`}
                    id={`lang-${item.id}`}
                    placeholder={"Language"}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`language.${index}.level`}
                render={({ field }) => (
                  <CustomSelect
                    label={"Proficiency"}
                    placeholder={"B2"}
                    options={PROFICIENCIES}
                    {...field}
                  />
                )}
              />
            </div>
            <DeleteIcon onClick={() => removeLanguage(index)} />
          </div>
        ))}
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.infoBlockLabel}>
          <p>Add another work experience</p>
          <PlusIcon
            onClick={() => addExperience({ title: "", experience: "" })}
          />
        </div>
        {experienceFields.map((item, index) => (
          <div
            key={item.id}
            className={styles.infoBlockGeneral}
          >
            <div className={styles.infoInputWrapper}>
              <Controller
                control={control}
                name={`another_works.${index}.title`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Work"}
                    // @ts-ignore
                    name={`another_works.${index}.title`}
                    id={`work-${item.id}`}
                    placeholder={"Work"}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`another_works.${index}.experience`}
                render={({ field }) => (
                  <InputWrapper
                    type={"text"}
                    title={"Years of experience"}
                    // @ts-ignore
                    name={`another_works.${index}.experience`}
                    id={`work-exp-${item.id}`}
                    placeholder={"1-2"}
                    {...field}
                  />
                )}
              />
            </div>
            <DeleteIcon onClick={() => removeExperience(index)} />
          </div>
        ))}
      </div>
    </>
  );

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      closeResumeDialog();
    }
  };
  return (
    <>
      <div
        className={styles.backdrop}
        onClick={handleClickOutside}
      ></div>
      <div
        className={resumeDialogOpen ? styles.openDialog : styles.closeDialog}
      >
        <div className={styles.dialogTitle}>
          <h3 className={styles.title}>Edit info about you</h3>
          <CloseIcon onClick={closeResumeDialog} />
        </div>
        <div className={styles.dialogContent}>
          {saved ? <p className={styles.saved}>{saved}</p> : null}
          <div className={styles.inputWrapper}>
            <div
              {...getRootProps()}
              className={styles.dropzone}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className={styles.active}>Drop the files here ...</p>
              ) : (
                <div>
                  <h2 className={styles.title}>Your CV</h2>
                  <p className={styles.inactive}>
                    Drag here or{" "}
                    <span className={styles.link}>open folder</span> to upload
                    new CV
                  </p>
                  {file && (
                    <div className={styles.fileInfo}>
                      <span className={styles.fileName}>{file?.name}</span>
                      <img
                        src="/images/cv.svg"
                        alt="file icon"
                        className={styles.fileIcon}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {fileError && <p className={styles.fileError}>{fileError}</p>}
          </div>
          <Step1 />
          <Step2 />
        </div>
        <div className={styles.dialogFooter}>
          <Button
            text={"Save changes"}
            color={"blue"}
            onClick={handleSubmit(onSubmit)}
            className={styles.submitButton}
          />
          <Button
            text={"Cancel"}
            color={"white"}
            className={styles.cancelButton}
            onClick={closeResumeDialog}
          />
        </div>
      </div>
    </>
  );
};

export default EditResumeDialog;
