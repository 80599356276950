import React, { FC, useEffect, useState } from 'react';
import Select, { components } from 'react-select';

import { handleFetchCities } from '../../utils/fetchDataUtils';
import { ReactComponent as DropDownIcon } from "../../assets/icons/dropDownIcon.svg";

import styles from './citySelect.module.css';

interface CitySelectProps {
    value: string;
    onChange: (value: string) => void;
}

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        padding: '12px 16px',
        borderRadius: '54px',
        backgroundColor: '#f7f8fb',
        color: '#a6a6ad',
        height: '48px',
        border: 'unset',
        boxShadow: 'none',
    }),
    input: (provided: any) => ({
        ...provided,
        color: '#000',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: '0',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: '#000',
        fontSize: '18px',
        textAlign: 'left',

    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: '#a6a6ad',
        fontSize: '18px',
    }),
    menu: (provided: any) => ({
        ...provided,
        borderRadius: '8px',
        marginTop: '4px',
    }),
    menuList: (provided: any) => ({
        ...provided,
        padding: '0',
        borderRadius: '8px',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        padding: '12px 16px',
        backgroundColor: state.isSelected ? '#0052cc' : state.isFocused ? '#e7eaf1' : '#f7f8fb',
        color: state.isSelected ? '#fff' : '#333',
        cursor: 'pointer',
        ':active': {
            backgroundColor: '#0052cc',
            color: '#fff',
        },
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
        border: 'none',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
    }),
};

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <DropDownIcon className={`${styles.arrow} ${props.isFocused && styles.arrowFocused}`} />
        </components.DropdownIndicator>
    );
};

const CitySelect: FC<CitySelectProps> = ({ value, onChange }) => {
    const [options, setOptions] = useState<any[]>([]);

    const handleSetOptions = (options: any[]) => setOptions(options);

    useEffect(() => {
        if (value) {
            handleFetchCities(value, handleSetOptions);
        }
    }, [value]);

    return (
        <div className={styles.selectWrapper}>
            <label className={styles.label}>City</label>
            <Select
                styles={customStyles}
                components={{
                    DropdownIndicator
                }}
                value={options.find((option) => option.value === value) || options[0]}
                onChange={(option) => onChange(option?.value || '')}
                onInputChange={(inputValue: string) => {
                    if (inputValue) {
                        handleFetchCities(inputValue, setOptions);
                    }
                }}
                options={options}
                placeholder="Select a city*"
            />
        </div>
    );
};

export default CitySelect;
