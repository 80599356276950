import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import {useAuth} from "../../context/AuthContext";
import {useProfile} from "../../context/ProfileContext";

const PrivateRoute: React.FC = () => {
    const { profileData } = useProfile();
    return useIsAuthenticated() ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
