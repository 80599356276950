import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import debounce from "lodash.debounce";

export interface Job {
  id: string;
  position_name: string;
  company_name: string;
  link: string;
  description: string;
}

interface FilterDates {
  startDate: Date | null;
  endDate: Date | null;
}

interface JobContextProps {
  jobs: Job[];
  filteredJobs: Job[];
  currentPage: number;
  totalPages: number;
  currentDayJobCount: number;
  setCurrentPage: (page: number) => void;
  loadPage: (
    page: number,
    startDate: Date | null,
    endDate: Date | null
  ) => void;
  filterDates: FilterDates;
  setFilterDates: (dates: FilterDates) => void;
  setFilteredJobs: (jobs: Job[]) => void;
  itemsPerPage: number;
}

const JobContext = createContext<JobContextProps | undefined>(undefined);

export const JobProvider = ({ children }: { children: ReactNode }) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [filterDates, setFilterDates] = useState<FilterDates>({
    startDate: null,
    endDate: null,
  });
  const [currentDayJobCount, setCurrentDayJobCount] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);

  const token = localStorage.getItem("authToken");
  const today = new Date().toISOString().split("T")[0];

  const updateItemsPerPage = useCallback(() => {
    if (window.innerWidth < 1700) {
      setItemsPerPage(5);
    } else {
      setItemsPerPage(8);
    }
  }, []);

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, [updateItemsPerPage]);

  const fetchJobCountForToday = useCallback(
    debounce(async (page: number, items_per_page: number, today: string) => {
      try {
        const requestBody: any = {
          items_per_page: 150,
          page,
        };

        if (today) {
          requestBody.dates = [
            {
              date: new Date().toISOString().split("T")[0],
            },
            {
              date: new Date().toISOString().split("T")[0],
            },
          ];
        }

        const response = await fetch("https://raislyhq.com/api/vacancy-list/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error ${response.status}: ${errorText}`);
        }
        const data = await response.json();

        setCurrentDayJobCount(data.vacancies.length);
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    }, 500),
    [token]
  );

  const loadPage = useCallback(
    debounce(
      async (page: number, startDate: Date | null, endDate: Date | null) => {
        try {
          const requestBody: any = {
            items_per_page: itemsPerPage,
            page,
          };

          if (startDate && endDate) {
            requestBody.date_interval_start = startDate
              .toISOString()
              .split("T")[0];
            requestBody.date_interval_end = endDate.toISOString().split("T")[0];
          } else if (startDate) {
            requestBody.dates = [
              {
                date: startDate.toISOString().split("T")[0],
              },
            ];
          }

          const response = await fetch(
            "https://raislyhq.com/api/vacancy-list/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error ${response.status}: ${errorText}`);
          }
          const data = await response.json();

          setJobs(data.vacancies);
          setFilteredJobs(data.vacancies);
          setCurrentPage(data.current_page);
          setTotalPages(data.pages_amount);
        } catch (error) {
          console.error("Error fetching job data:", error);
        }
      },
      500
    ),
    [itemsPerPage, token]
  );

  useEffect(() => {
    // TODO треба зафіксати ця частина викликає за весь період а нам треба лише за один день
    // loadPage(currentPage, filterDates.startDate, filterDates.endDate);
  }, [currentPage, filterDates, loadPage]);

  
  useEffect(() => {
    if (filterDates.startDate || filterDates.endDate) {
     
      const filtered = jobs.filter((job) => {
        return true;
      });
      setFilteredJobs(filtered);
    } else {
      setFilteredJobs(jobs);
    }
  }, [filterDates, jobs]);

  useEffect(() => {
    fetchJobCountForToday(1, 150, today);
  }, [fetchJobCountForToday]);

  return (
    <JobContext.Provider
      value={{
        jobs,
        filteredJobs,
        currentPage,
        totalPages,
        currentDayJobCount,
        setCurrentPage,
        loadPage,
        filterDates,
        setFilterDates,
        setFilteredJobs,
        itemsPerPage,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};

export const useJobContext = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error("useJobContext must be used within a JobProvider");
  }
  return context;
};
