import React from "react";
import styles from "../TermsOfUsePage/page.module.css";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";

export const PrivacyPolicyPage = () => {
  return (
    <>
      <Header
        scrollToAbout={() => {}}
        scrollToPlans={() => {}}
        scrollToHowItWorks={() => {}}
      />
      <main className={styles.pageWrapper}>
        <h1 className={styles.pageTitle}>Privacy policy</h1>
        <p className={styles.publication}>Publication date 24.06.2024</p>

        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>1. Introduction</h2>
          <p className={styles.paragraph}>
            This privacy policy (“Policy”) describes how Raisly in Canada
            (“Company,” “we,” and “our”) processes, collects, uses and shares
            personal data when using this website http://www.raislyhq.com (the
            “Website”). Please read the following information carefully to
            understand our practices regarding your personal data and how we
            will process data.
          </p>
        </div>

        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>2. Purposes of Processing</h2>
          <h3 className={styles.subtitle}>2.1 What is personal data?</h3>
          <p className={styles.paragraph}>
            Within the context of this Policy, we collect various forms of
            information, encompassing what is commonly referred to as "personal
            data." As delineated in the General Data Protection Regulation
            (GDPR), the term "personal data" pertains to any information that,
            either individually or when amalgamated with other data under our
            purview, serves to uniquely identify you as an individual. Such data
            may encompass, but is not restricted to, particulars such as your
            name, postal address, email address, and telephone number.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            2.2 Why do we need your personal data?
          </h3>
          <p className={styles.paragraph}>
            Our processing of personal data adheres strictly to the provisions
            outlined in relevant data protection and privacy legislation. The
            collection of specific personal data is indispensable for the
            provision of access to our website. When you register with us, you
            provide your consent, signifying your willingness to furnish this
            information to facilitate access to our services, product
            acquisition, or content perusal. This consent establishes the
            requisite legal foundation under applicable law for the processing
            of your data. It is important to note that you retain the
            prerogative to revoke this consent at any juncture. If you dissent
            with our utilization of your personal data in consonance with the
            tenets expounded in this Policy, we kindly beseech you to refrain
            from using our website.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>3. Collecting Your Personal Data</h2>
          <p className={styles.paragraph}>
            We collect information about you in the following ways:
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            3.1 Information You Give Us. This includes:
          </h3>
          <ul className={styles.pageList}>
            <li>
              the personal data you provide when you register to use our
              website, including your name, postal address, email address,
              telephone number, username, password and demographic information
              (such as your gender);
            </li>
            <li>
              the personal data that may be contained in any video, comment or
              other submission you upload or post to the website;
            </li>
            <li>
              the personal data you provide in connection with our rewards
              program and other promotions we run on the website;
            </li>
            <li>
              the personal data you provide when you report a problem with our
              website or when we provide you with customer support;
            </li>
            <li>
              the personal data you provide when you make a purchase through our
              website; and
            </li>
            <li>
              the personal data you provide when you correspond with us by
              phone, email or otherwise.
            </li>
          </ul>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            3.2 Information from Social Networking Websites.
          </h3>
          <p className={styles.paragraph}>
            Our website includes interfaces that allow you to connect with
            social networking sites (each a “SNS”). If you connect to a SNS
            through our website, you authorize us to access, use and store the
            information that you agreed the SNS could provide to us based on
            your settings on that SNS. We will access, use and store that
            information in accordance with this Policy. You can revoke our
            access to the information you provide in this way at any time by
            amending the appropriate settings from within your account settings
            on the applicable SNS.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            3.3 Information Automatically Collected.
          </h3>
          <p className={styles.paragraph}>
            We automatically log information about you and your computer or
            mobile device when you access our website. For example, when
            visiting our website, we log your computer or mobile device
            operating system name and version, manufacturer and model, browser
            type, browser language, screen resolution, the website you visited
            before browsing to our website, pages you viewed, how long you spent
            on a page, access times and information about your use of and
            actions on our website. We collect this information about you using
            cookies.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            3.4 Automated Decision Making and Profiling.
          </h3>
          <p className={styles.paragraph}>
            We do not use your personal data for the purposes of automated
            decision-making. However, we may do so in order to fulfill
            obligations imposed by law, in which case we will inform you of any
            such processing and provide you with an opportunity to object.
          </p>
        </div>

        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>4. Use of Data for Chrome Extension</h2>
          <p className={`${styles.paragraph}  `}>
            We use the personal data we collect to operate and enhance the
            functionality of our Chrome extension designed for LinkedIn Easy
            Apply job applications. Specifically, the information provided by
            users, such as job preferences, resumes, and other related details,
            is utilized to:
          </p>
          <p className={styles.mtSubtitle}></p>
          <p className={`${styles.paragraph} ${styles.listTitle}`}>
            <b>Identify Relevant Job Opportunities:</b> The extension analyzes
            LinkedIn job postings and applies filters based on the information
            and preferences you provide, such as desired job roles, locations,
            and other criteria.
          </p>
          <p className={`${styles.paragraph} ${styles.listTitle2}`}>
            <b>Automatic Job Application:</b> The extension automatically
            submits job applications on your behalf for positions that match the
            provided criteria. This process includes filling out necessary
            application fields and attaching your resume and other required
            documents.
          </p>
          <p className={`${styles.paragraph} ${styles.listTitle3}`}>
            <b>Application Tracking:</b> The extension saves a record of the
            jobs you have applied for, allowing you to keep track of your
            application history. Note that the extension does not provide
            updates on the status of your applications.
          </p>
          <p className={styles.mtSubtitle}></p>
          <p className={`${styles.paragraph}`}>
            We do not use the information for purposes beyond these
            functionalities without your explicit consent. All data processing
            is performed under strict adherence to applicable data protection
            laws, ensuring that your personal data is handled with the utmost
            care and security.
          </p>
        </div>

        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>5. Cookies</h2>
          <h3 className={styles.subtitle}>5.1 What are cookies?</h3>
          <p className={styles.paragraph}>
            The collection of information through the employment of "cookies" is
            a practice that we may undertake. Cookies, in this context,
            represent diminutive data files that are housed within the hard
            drive of your computing apparatus or mobile device by a given
            website. Our usage encompasses two primary categories of cookies:
            session cookies, which have a transitory existence and expire upon
            the closure of your web browser, and persistent cookies, which
            endure on your computing apparatus or mobile device until you choose
            to remove them. These cookies are instrumental in providing you with
            an enhanced, individualized, and interactive experience during your
            interaction with our website.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            5.2 Two Distinct Categories of Cookies
          </h3>
          <p className={styles.paragraph}>
            Our utilization of cookies comprises two distinct classifications:
          </p>
          <p className={`${styles.paragraph} ${styles.listTitle}`}>
            First Party Cookies: These are directly dispensed by us to your
            computing apparatus or mobile device, serving the sole purpose of
            recognizing your computing apparatus or mobile device when you
            revisit our website.
          </p>
          <p className={`${styles.paragraph} ${styles.listTitle2}`}>
            Third Party Cookies: These are administered by service providers who
            operate on our website. Service providers may harness these cookies
            to identify your computing apparatus or mobile device not only on
            our website but also during its visits to other websites.
          </p>
          <p className={styles.paragraph}>
            Cookies are instrumental in enhancing the functionality and
            personalization of your web experience, thereby facilitating an
            enriched user interaction with our website.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            5.3 Cookies we use
          </h3>
          <p className={styles.paragraph}>
            <b>Essential Cookies:</b> These cookies are essential to provide you
            with services available through our website and to enable you to use
            some of its features. For example, they allow you to log in to
            secure areas of our website and help the content of the pages you
            request load quickly. Without these cookies, the services that you
            have asked for cannot be provided, and we only use these cookies to
            provide you with those services.
          </p>
          <p className={styles.paragraph}>
            <b>Functionality Cookies:</b> These cookies allow our website to
            remember choices you make when you use our website, such as
            remembering your language preferences, remembering your login
            details and remembering the changes you make to other parts of our
            website which you can customize. The purpose of these cookies is to
            provide you with a more personal experience and to avoid you having
            to re-enter your preferences every time you visit our website.
          </p>
          <p className={styles.paragraph}>
            <b>Analytics and Performance Cookies:</b> These cookies are used to
            collect information about traffic to our website and how users use
            our website. The information gathered does not identify any
            individual visitor. It includes the number of visitors to our
            website, the websites that referred them to our website, the pages
            they visited on our website, what time of day they visited our
            website, whether they have visited our website before, and other
            similar information. We use this information to help operate our
            website more efficiently, to gather broad demographic information
            and to monitor the level of activity on our website.
          </p>
          <p className={styles.paragraph}>
            We use Google Analytics for this purpose. Google Analytics uses its
            own cookies. It is only used to improve how our website works. You
            can find out more information about Google Analytics cookies here:{" "}
            <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">
              https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
            </a>
          </p>
          <p className={styles.paragraph}>
            You can find out more about how Google protects your data here:{" "}
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
            .
          </p>
          <p className={styles.paragraph}>
            You can prevent the use of Google Analytics relating to your use of
            our website by downloading and installing the browser plugin
            available via this link:{" "}
            <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">
              http://tools.google.com/dlpage/gaoptout?hl=en-GB
            </a>
          </p>
          <p className={styles.paragraph}>
            <b>Targeted and advertising cookies:</b> These cookies track your
            browsing habits to enable us to show advertising which is more
            likely to be of interest to you. These cookies use information about
            your browsing history to group you with other users who have similar
            interests. Based on that information, and with our permission, third
            party advertisers can place cookies to enable them to show adverts
            which we think will be relevant to your interests while you are on
            third party websites.
          </p>
          <p className={styles.paragraph}>
            You can disable cookies which remember your browsing habits and
            target advertising at you by visiting{" "}
            <a href="http://www.youronlinechoices.com/uk/your-ad-choices">
              http://www.youronlinechoices.com/uk/your-ad-choices
            </a>
            . If you choose to remove targeted or advertising cookies, you will
            still see adverts but they may not be relevant to you. Even if you
            do choose to remove cookies by the companies listed at the above
            link, not all companies that serve online behavioral advertising are
            included in this list, and so you may still receive some cookies and
            tailored adverts from companies that are not listed.
          </p>
          <p className={styles.paragraph}>
            <b>Social Media Cookies:</b> These cookies are used when you share
            information using a social media sharing button or “like” button on
            our website or you link your account or engage with our content on
            or through a social networking website such as Facebook, Twitter or
            Google+. The social network will record that you have done this.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            5.4 Disabling cookies
          </h3>
          <p className={styles.paragraph}>
            It is generally feasible to eliminate or decline the use of cookies
            by adjusting your browser settings. To effect this, kindly adhere to
            the guidance presented by your browser, typically accessible within
            the "settings," "help," "tools," or "edit" features. The majority of
            browsers are initially configured to accept cookies until such time
            as you make modifications to your settings.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            5.5 Potential Implications:
          </h3>
          <p className={styles.paragraph}>
            Should you opt not to accept our cookies, it is conceivable that you
            may encounter certain inconveniences while utilizing our website.
            For instance, there may be an inability to recognize your computing
            apparatus or mobile device, necessitating repeated login procedures
            on each visit to our website.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>6. Advertising</h2>
          <p className={styles.paragraph}>
            We may use other companies to serve third-party advertisements when
            you visit and use the website. These companies may collect and use
            click stream information, browser type, time and date, subject of
            advertisements clicked or scrolled over during your visits to the
            website and other websites in order to provide advertisements about
            goods and services likely to be of interest to you. These companies
            typically use tracking technologies to collect this information.
            Other companies' use of their tracking technologies is subject to
            their own privacy policies.
          </p>
          <h3 className={`${styles.subtitle} ${styles.mtSubtitle}`}>
            6.1 Using Your Personal Data
          </h3>
          <p className={styles.paragraph}>
            We may use your personal data as follows:
          </p>
          <ul className={styles.pageList}>
            <li>
              to operate, maintain, and improve our website, products, and
              services;
            </li>
            <li>
              to manage your account, including to communicate with you
              regarding your account, if you have an account on our website;
            </li>
            <li>
              to operate and administer our rewards program and other promotions
              you participate in on our website;
            </li>
            <li>
              to respond to your comments and questions and to provide customer
              service;
            </li>
            <li>
              to send information including technical notices, updates, security
              alerts, and support and administrative messages;
            </li>
            <li>
              with your consent, to send you marketing e-mails about upcoming
              promotions, and other news, including information about products
              and services offered by us and our affiliates. You may opt-out of
              receiving such information at any time: such marketing emails tell
              you how to “opt-out.” Please note, even if you opt out of
              receiving marketing emails, we may still send you non-marketing
              emails. Non-marketing emails include emails about your account
              with us (if you have one) and our business dealings with you;
            </li>
            <li>to process payments you make via our website;</li>
            <li>
              as we believe necessary or appropriate (a) to comply with
              applicable laws; (b) to comply with lawful requests and legal
              process, including to respond to requests from public and
              government authorities; (c) to enforce our Policy; and (d) to
              protect our rights, privacy, safety or property, and/or that of
              you or others;
            </li>
            <li>for analysis and study services; and</li>
            <li>
              as described in the “Sharing of your Personal Data” section below.
            </li>
          </ul>
          <p className={styles.paragraph}>
            The Company does not control the Linked Sites, and shall not be
            responsible for the content and other materials of the Linked Sites.
            The Company makes these links available to you for providing the
            functionality or services on the website.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>7. Sharing Your Personal Data</h2>
          <p className={styles.paragraph}>
            We may share your personal data as follows:
          </p>
          <ul className={styles.pageList}>
            <li>
              Third Parties Designated by You. We may share your personal data
              with third parties where you have provided your consent to do so.
            </li>
            <li>
              Our Third Party Service Providers. We may share your personal data
              with our third party service providers who provide services such
              as data analysis, payment processing, information technology and
              related infrastructure provision, customer service, email
              delivery, auditing and other similar services.
            </li>
            <li>Third Party Sites</li>
          </ul>
          <p className={styles.paragraph}>
            Our website may contain links to third party websites and
            features. This Policy does not cover the privacy practices of such
            third parties. These third parties have their own privacy policies
            and we do not accept any responsibility or liability for their
            websites, features or policies. Please read their privacy policies
            before you submit any data to them.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>8. User Generated Content</h2>
          <p className={styles.paragraph}>
            You may share personal data with us when you submit user-generated
            content to our website, including via our rewards program, forums,
            message boards, and Websites on our website. Please note that any
            information you post or disclose on our website will become public
            information, and will be available to other users of our website and
            to the general public. We urge you to be very careful when deciding
            to disclose your personal data, or any other information, on our
            website. Such personal data and other information will not be
            private or confidential once it is published on our website.
          </p>
          <p className={styles.paragraph}>
            If you provide feedback to us, we may use and disclose such feedback
            on our website, provided we do not associate such feedback with your
            personal data. If you have provided your consent to do so, we may
            post your first and last name along with your feedback on our
            website. We will collect any information contained in such feedback
            and will treat the personal data in it in accordance with this
            Policy.
          </p>
          <p className={styles.paragraph}>
            It is prohibited to upload, post, transmit, or otherwise make
            available content through the Application which includes or
            expresses, without limitation, any abusive behavior, threats,
            harassment, bullying, malicious attacks, ridicule, graphic
            materials, gambling advertising, sexually explicit materials,
            promotion of or urge to use drugs or any other illicit substances,
            or any materials inconsistent with the provisions of applicable law
            (“Objectionable Content and Behavior”).
          </p>
          <p className={styles.paragraph}>
            We reserve our right to take reasonable measures to expeditiously
            remove from the Application any Objectionable Content and Behavior
            that we become aware of. It is our policy, in appropriate
            circumstances and at our discretion, to disable or terminate the
            accounts of users of the Application who repeatedly violate the
            provisions of the previous paragraph. If you have found any
            Objectionable Content and Behavior or wish to file a complaint about
            any other information or materials uploaded by other users, please
            contact us directly. However, it is up to our discretion to decide
            whether to take any action in connection with your complaint.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>9. International Data Transfer</h2>
          <p className={styles.paragraph}>
            Your information, including personal data that we collect from you,
            may be transferred to, stored at and processed by us outside the
            country in which you reside, where data protection and privacy
            regulations may not offer the same level of protection as in other
            parts of the world. By accepting this Policy, you agree to this
            transfer, storing or processing. We will take all steps reasonably
            necessary to ensure that your data is treated securely and in
            accordance with this Policy.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>10. Security</h2>
          <p className={styles.paragraph}>
            We seek to use reasonable organizational, technical and
            administrative measures to protect personal data within our
            organization. Unfortunately, no transmission or storage system can
            be guaranteed to be completely secure, and transmission of
            information via the Internet is not completely secure. If you have
            reason to believe that your interaction with us is no longer secure
            (for example, if you feel that the security of any account you might
            have with us has been compromised), please immediately notify us of
            the problem by contacting us.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>11. Retention</h2>
          <p className={styles.paragraph}>
            We will only retain your personal data as long reasonably required
            for you to use the website until you close your account/cancel your
            subscription unless a longer retention period is required or
            permitted by law (for example for regulatory purposes).
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>12. Our Policy on Children</h2>
          <p className={styles.paragraph}>
            Our website is/are not directed to children under 16. If a parent or
            guardian becomes aware that his or her child has provided us with
            information without their consent, he or she should contact us. We
            will delete such information from our files as soon as reasonably
            practicable.
          </p>
          <p className={styles.paragraph}>Your Rights</p>
          <ul className={styles.pageList}>
            <li>
              Opt-out. You may contact us anytime to opt-out of: (i) direct
              marketing communications; (ii) automated decision-making and/or
              profiling; (iii) our collection of sensitive personal data; (iv)
              any new processing of your personal data that we may carry out
              beyond the original purpose; or (v) the transfer of your personal
              data outside the EEA. Please note that your use of some of the
              website may be ineffective upon opt-out.
            </li>
            <li>
              Access. You may access the information we hold about you at any
              time via your profile/account or by contacting us directly.
            </li>
            <li>
              Amend. You can also contact us to update or correct any
              inaccuracies in your personal data.
            </li>
            <li>
              Move. Your personal data is portable – i.e. you to have the
              flexibility to move your data to other service providers as you
              wish.
            </li>
            <li>
              Erase and forget. In certain situations, for example when the
              information we hold about you is no longer relevant or is
              incorrect, you can request that we erase your data.
            </li>
          </ul>
          <p className={styles.paragraph}>
            If you wish to exercise any of these rights, please contact us. In
            your request, please make clear: (i) what personal data is
            concerned; and (ii) which of the above rights you would like to
            enforce. For your protection, we may only implement requests with
            respect to the personal data associated with the particular email
            address that you use to send us your request, and we may need to
            verify your identity before implementing your request. We will try
            to comply with your request as soon as reasonably practicable and in
            any event, within one month of your request. Please note that we may
            need to retain certain information for recordkeeping purposes and/or
            to complete any transactions that you began prior to requesting such
            change or deletion.
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>13. Complaints</h2>
          <p className={styles.paragraph}>
            We are committed to resolve any complaints about our collection or
            use of your personal data. If you would like to make a complaint
            regarding this Policy or our practices in relation to your personal
            data, please contact us through the information listed on our
            website. We will reply to your complaint as soon as we can and in
            any event, within 30 days. We hope to resolve any complaint brought
            to our attention, however if you feel that your complaint has not
            been adequately resolved, you reserve the right to contact your
            local data protection supervisory authority
          </p>
        </div>
        <div className={styles.paragraphWrapper}>
          <h2 className={styles.title}>14. Contact Information</h2>
          <p className={styles.paragraph}>
            We welcome your comments or questions about this Policy. You may
            contact us in through our website.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};
