import React, {FC, useEffect, useState} from 'react';

import styles from "./page.module.css";
import {useProfile} from "../../context/ProfileContext";
import {Button} from "../../components/Button/Button";
import backArrow from "../../assets/icons/backArrow.svg";
import {useNavigate} from "react-router";
import {useDialog} from "../../context/DialogContext";

export const SubscriptionPage: FC = () => {
    const navigate = useNavigate();

    const { openCancelSubDialog } = useDialog();
    const { handleGetSubscription, handleDeleteSubscription } = useProfile();

    const [subscription, setSubscription] = useState<any>();

    const onBackButtonClick = () => navigate(-1)

    const fetchSubscription = async () => {
        const data = await handleGetSubscription();
        setSubscription(data.subscription);
    }

    useEffect(() => {
        fetchSubscription();
    }, []);
    return (
        <div className={styles.signInPage}>
            <div className={styles.content}>
                <div className={styles.topAction}>
                    <button className={styles.backButton} onClick={onBackButtonClick}>
                        <img src={backArrow} alt="Go Back"/>
                    </button>
                    <h1 className={styles.title}>{!!subscription ? "Manage your subscription" : "You have no subscription yet" }</h1>
                </div>
                {!!subscription ? (
                    <div className={styles.subscriptionWrapper}>
                        <div className={styles.topSection}>
                            <h2 className={styles.subscriptionTitle}>{subscription?.plan?.name}</h2>
                        </div>
                        <div className={styles.bottomSection}>
                            <div className={styles.infoSection}>
                                <div className={`${styles.infoBlock} ${styles.left}`}>
                                    {subscription.is_active ? (
                                        <div className={styles.infoItem}>
                                            <p>Next billing</p>
                                            <p className={styles.infoSecond}>{subscription?.end_date}</p>
                                        </div>
                                    ) : (
                                        <div className={styles.infoItem}>
                                            <p className={styles.infoSecond}>Your subscription is expired</p>
                                        </div>
                                    )}
                                    <div className={styles.infoItem}>
                                        <p>Last billing</p>
                                        <p className={styles.infoSecond}>{subscription?.start_date}</p>
                                    </div>
                                </div>
                                <div className={`${styles.infoBlock} ${styles.right}`}>
                                    <div className={styles.infoItem}>
                                        <p>Next billing price</p>
                                        <p className={styles.infoSecond}>${subscription?.plan?.price}</p>
                                    </div>
                                </div>
                            </div>
                            {subscription.is_active ? (
                                <Button onClick={openCancelSubDialog} color={"blue"} text={"Cancel subscription"} />
                            ) : (
                                <div className={styles.buttons}>
                                    <Button onClick={() => {}} color={"blue"} text={"Renew subscription"} />
                                    <Button onClick={() => navigate("/change-subscription")} color={"white"} text={"Change subscription"} />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={styles.buttonWrapper}>
                        <Button onClick={() => navigate("/select-subscription")} color={"blue"} text={"Choose your plan"} />
                    </div>
                )}

            </div>
        </div>
    );
};