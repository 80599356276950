import React, {FC, useEffect, useState} from 'react';
import styles from "../SignInPage/page.module.css";
import {ForgotPasswordWrapper} from "../../components/ForgotPasswordWrapper/ForgotPasswordWrapper";
import {InputWrapper} from "../../components/common/InputWrapper/InputWrapper";
import {Button} from "../../components/Button/Button";
import {validateEmail} from "../../utils/validationUtil";
import {PASSWORD_RESTORE_PAGES_URL, useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router";
import {useProfile} from "../../context/ProfileContext";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

export const ForgotPasswordPage: FC = () => {
    const navigate = useNavigate();
    const auth = useIsAuthenticated();
    const { error, handleGetEmailResetLink } = useAuth();
    const { profileData, handleGetProfileData } = useProfile();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const onContinueClick = async () => {
        setLoading(true);
        try {
            const data = await handleGetEmailResetLink(email);
            if (data) {
                navigate(`/${PASSWORD_RESTORE_PAGES_URL.CHECK_YOUR_MAIL}?email=${email}`);
            }
        } catch (err) {

        }
        setLoading(false);
    }

    useEffect(() => {
        if (auth) {
            handleGetProfileData();
        }
    }, [auth]);
    useEffect(()=>{
        if (profileData?.email){
            setEmail(profileData?.email)
        }
    },[profileData?.email])
    return (
        <div className={styles.signInPage}>
            <ForgotPasswordWrapper pageTitle={"Enter your email"}>
                <InputWrapper
                  isDisabled={auth}
                    type={"email"}
                    title={"Enter your email"}
                    name={"email"}
                    id={"usr-email"}
                    onChange={setEmail}
                    value={email}
                    placeholder={"example@gmail.com"}
                />
                <br/>
                <Button disabled={!validateEmail(email) || loading} onClick={onContinueClick} color={"blue"} text={"Continue"} />
            </ForgotPasswordWrapper>
        </div>
    );
};
