import axios from "axios";

interface DownloadParams {
  format: string;
  dateIntervalStart?: string;
  dateIntervalEnd?: string;
  authToken: string;
  downloadAll: boolean;
}

export const downloadVacancies = async (params: DownloadParams): Promise<{ success: boolean; message?: string }> => {
  const { format, dateIntervalStart, dateIntervalEnd, authToken, downloadAll } = params;

  const url = `https://raislyhq.com/api/vacancy/download/${format}`;

  const requestData = downloadAll
    ? {}
    : {
        date_interval_start: dateIntervalStart,
        date_interval_end: dateIntervalEnd,
      };

  try {
    const response = await axios.post(url, requestData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `vacancies.${format}`;
    link.click();

    return { success: true };
  } catch (error) {
    let errorMessage = "An error occurred while downloading vacancies.";
    if (axios.isAxiosError(error) && error.response) {
      errorMessage = error.response.data?.message || errorMessage;
    }
    console.error(`Error downloading vacancies as ${format}:`, error);
    return { success: false, message: errorMessage };
  }
};
