import React, {FC, PropsWithChildren, useState} from 'react';
import {useNavigate} from "react-router";

import backArrow from "../../assets/icons/backArrow.svg";

import styles from "./forgotPasswordWrapper.module.css";

interface AuthWrapperProps {
    pageTitle: string;
    backButton?: boolean;
    disableTabAction?: boolean;
    backButtonCallback?: () => void;
}

export const ForgotPasswordWrapper: FC<AuthWrapperProps & PropsWithChildren> = ({ pageTitle = "",  children, backButtonCallback, backButton = true }) => {
    const navigate = useNavigate();

    const onBackButtonClick = () => navigate(-1)

    return (
        <div className={styles.authWrapper}>
            <div className={styles.topAction}>
                {backButton && (
                    <button className={styles.backButton} onClick={!!backButtonCallback ? backButtonCallback : onBackButtonClick}>
                        <img src={backArrow} alt="Go Back"/>
                    </button>
                )}
                <h1 className={styles.title}>{pageTitle}</h1>
            </div>
            {children}
        </div>
    );
};