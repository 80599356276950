import axios from 'axios';

export const handleFetchCities = async (
    value: string,
    callback: (cities: any[]) => void,
) => {
    try {
        const data = await axios
            .get(
                `https://secure.geonames.org/searchJSON?q=${value}&maxRows=10&username=personallity_assess`,
            )
            .then((response) => response.data.geonames);

        const cities = data.map((object: any) => ({
            label: `${object.name}, ${object.countryName}`,
            value: `${object.name}/${object.countryName}`,
        }));

        callback(cities);
    } catch (error) {
        console.error(error);
    }
};
