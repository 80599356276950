import React, {useState, useRef, FC} from 'react';

import { ReactComponent as RemoveIcon } from "../../assets/icons/removeIcon.svg";

import styles from './SkillsInput.module.css';

export interface Skill {
    id: number;
    name: string;
}

interface SkillsInputProps {
  skills: Skill[];
  setSkills: (value: Skill[]) => void;
}

const SkillsInput: FC<SkillsInputProps> = ({ skills, setSkills }) => {
    const [currentSkill, setCurrentSkill] = useState('');
    const [inputWidth, setInputWidth] = useState(125);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCurrentSkill(value);
        const width = Math.min(350, Math.max(125, value.length * 8 + 20));
        setInputWidth(width);
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && currentSkill.trim()) {
            setSkills([...skills, { id: Date.now(), name: currentSkill.trim() }]);
            setCurrentSkill('');
            setInputWidth(125);
        }
    };

    const handleBlur = () => {
        if (currentSkill.trim()) {
            setSkills([...skills, { id: Date.now(), name: currentSkill.trim() }]);
            setCurrentSkill('');
            setInputWidth(125);
        }
    };

    const handleRemoveSkill = (id: number) => {
        setSkills(skills.filter(skill => skill.id !== id));
    };

    return (
        <div className={styles.skillsInputContainer}>
            <label className={styles.label}>Your skills</label>
            <div className={styles.skillsWrapper}>
                {skills.map(skill => (
                    <div key={skill.id} className={styles.skillItem}>
                        {skill.name}
                        <RemoveIcon className={styles.removeIcon} onClick={() => handleRemoveSkill(skill.id)} />
                    </div>
                ))}
                {skills.length < 10 && (
                    <input
                        ref={inputRef}
                        type="text"
                        value={currentSkill}
                        onBlur={handleBlur}
                        onChange={handleInputChange}
                        onKeyUp={handleKeyPress}
                        className={styles.skillInput}
                        style={{ width: inputWidth }}
                        placeholder="Add more +"
                    />
                )}
            </div>
        </div>
    );
};

export default SkillsInput;
