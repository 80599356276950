import React, { useState, useEffect } from "react";
import "./Calendar.css";
import { useJobContext } from "../../context/JobContext";

const daysInWeek = 7;

const CustomCalendar: React.FC = () => {
  const { setFilterDates, setFilteredJobs } = useJobContext();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  useEffect(() => {
    const today = new Date();
    if (!startDate && !endDate) {
      setStartDate(today);
      setEndDate(null);
      setSelectedDates([today]);
      setFilterDates({ startDate: today, endDate: null });
    }
  }, [startDate, endDate, setFilterDates]);

  const handleDateClick = (date: Date) => {
    if (!startDate || endDate) {
      setStartDate(date);
      setEndDate(null);
      setSelectedDates([date]);
      setFilterDates({ startDate: date, endDate: null });
    } else if (startDate && !endDate) {
      if (date < startDate) {
        setStartDate(date);
        setEndDate(null);
        setSelectedDates([date]);
        setFilterDates({ startDate: date, endDate: null });
      } else {
        setEndDate(date);
        const range = getDatesInRange(startDate, date);
        setSelectedDates(range);
        setFilterDates({ startDate, endDate: date });
      }
    }

    setFilteredJobs([]);
  };

  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - daysInWeek);
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + daysInWeek);
    if (newDate <= new Date()) {
      setCurrentDate(newDate);
    }
  };

  const getDatesInRange = (startDate: Date, endDate: Date) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < daysInWeek; i++) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - daysInWeek + i + 1);

      if (date > new Date()) continue;

      const isSelected = selectedDates.some(
        (selectedDate) => selectedDate.toDateString() === date.toDateString()
      );

      const isStart =
        startDate && date.toDateString() === startDate.toDateString();
      const isEnd = endDate && date.toDateString() === endDate.toDateString();
      const inRange =
        startDate &&
        endDate &&
        date > startDate &&
        date < endDate &&
        isSelected;

      days.push(
        <div
          key={i}
          className={`day-wrapper ${
            isStart && isEnd
              ? "none-wrapper"
              : isStart && !isEnd
              ? "start-wrapper"
              : isEnd && !isStart
              ? "end-wrapper"
              : inRange
              ? "in-range"
              : "none-wrapper"
          }`}
        >
          <div
            className={`day ${
              isStart
                ? "selected selected-start"
                : isEnd
                ? "selected selected-end"
                : ""
            } `}
            onClick={() => handleDateClick(date)}
          >
            {date.getDate()}
          </div>
        </div>
      );
    }
    return days;
  };

  const isNextDisabled = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + daysInWeek);
    return newDate > new Date();
  };

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <h2 className="calendar-title">Application Calendar</h2>
        <p className="calendar-month">
          {currentDate.toLocaleString("en-US", {
            month: "long",
            year: "numeric",
          })}
        </p>
      </div>
      <p className="calendar-text">Choose a date you want to check info from</p>
      <div className="calendar-body">
        <button
          className="nav-button nav-button__prev"
          onClick={handlePrevious}
        >
          <img
            src="/images/left.svg"
            alt=""
          />
        </button>
        <div className="days-container">{renderDays()}</div>
        <button
          className="nav-button nav-button__next"
          onClick={handleNext}
          disabled={isNextDisabled()}
        >
          <img
            src="/images/right.svg"
            alt=""
            className={isNextDisabled() ? "disabled_next" : ""}
          />
        </button>
      </div>
    </div>
  );
};

export default CustomCalendar;
