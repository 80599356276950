import React, { useEffect, useRef, useState } from "react";
import { AboutSection } from "../../components/AboutSection/AboutSection";
import { DashboardSection } from "../../components/DashboardSection/DashboardSection";
import { OfferSection } from "../../components/OfferSection/OfferSection";
import { ReleasedSection } from "../../components/ReleasedSection/ReleasedSection";
import CustomSwiper from "../../components/Swiper/Swiper";
import { Title } from "../../components/Title/Title";
import style from "./HomePage.module.css";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import EditResumeDialog from "../../components/EditResumeDialog/EditResumeDialog";

const HomePage: React.FC = () => {
  const aboutSectionRef = useRef<HTMLDivElement>(null);
  const plansRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);

  const scrollToHowItWorks = () => {
    if (howItWorksRef.current) {
      howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAboutSection = () => {
    if (aboutSectionRef.current) {
      aboutSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToPlansSection = () => {
    if (plansRef.current) {
      plansRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header
        scrollToAbout={scrollToAboutSection}
        scrollToPlans={scrollToPlansSection}
        scrollToHowItWorks={scrollToHowItWorks}
      />

      <div className="container">
        <div ref={howItWorksRef}>
          <DashboardSection />
        </div>
        <Title title="Released features" />
        <div className={style.wrapper}>
          <ReleasedSection positionImages="right" />
        </div>
        <div>
          <ReleasedSection positionImages="left" />
        </div>
        <Title title="Future tools" />
        <CustomSwiper />
        <div ref={aboutSectionRef}>
          <AboutSection />
        </div>
        <div ref={plansRef}>
          <OfferSection />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default HomePage;
