import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./Swiper.css";

import {
  Navigation,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";

type SlideContent = {
  title: string;
  text: string;
  image: string;
};

const slideContent: SlideContent[] = [
  {
    title: "Salary negotiation",
    text: `Navigate salary negotiations with our expert advice. Learn how to show your value, negotiate, and get what you're worth.`,
    image: "/images/salary.svg",
  },
  {
    title: "Interview preparation",
    text: `Prepare for your interviews with confidence. We will share our resources, practice questions, and expert tips to make a great impression.`,
    image: "/images/micro.svg",
  },
  {
    title: "LinkedIn optimization",
    text: `Create a world of new possibilities with us, we will help you to utilize the power of LinkedIn.`,
    image: "/images/linkedin.svg",
  },
  {
    title: "Career growth",
    text: `Fuel your career growth with our expert advice. Learn how to ask for promotions, raises, and make informed career decisions.`,
    image: "/images/growth.svg",
  },
  {
    title: "Resume Builder",
    text: `Create a resume that stands out to employers. We will help you on every step so that you showcase your skills and experience in the best light.`,
    image: "/images/cv.svg",
  },
];

const CustomSwiper = () => {
  return (
    <Swiper
      spaceBetween={15}
      centeredSlides={true}
      slidesPerView={3.19}
      initialSlide={1}
      navigation={true}
      pagination={{ clickable: true }}
      loop={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      modules={[Navigation, Pagination, EffectCoverflow, Autoplay]}
      onInit={(swiper: any) => {
        const titles =
          swiper.slides[swiper.activeIndex].querySelectorAll(".title");
        titles.forEach((el: any) => el.classList.add("title-active"));

        const wrapperBottoms =
          swiper.slides[swiper.activeIndex].querySelectorAll(".wrapper_bottom");
        wrapperBottoms.forEach((el: any) =>
          el.classList.add("wrapper_bottom-active")
        );

        const wrapperTop =
          swiper.slides[swiper.activeIndex].querySelectorAll(".wrapper_top");
        wrapperTop.forEach((el: any) => el.classList.add("wrapper_top-active"));

        const icons =
          swiper.slides[swiper.activeIndex].querySelectorAll(".icon");
        icons.forEach((el: any) => el.classList.add("icon-active"));
      }}
      onSlideChange={(swiper: any) => {
        const titles = document.querySelectorAll(".title");
        titles.forEach((el: any) => el.classList.remove("title-active"));

        const activeTitles =
          swiper.slides[swiper.activeIndex].querySelectorAll(".title");
        activeTitles.forEach((el: any) => el.classList.add("title-active"));

        const wrapperBottoms = document.querySelectorAll(".wrapper_bottom");
        wrapperBottoms.forEach((el: any) =>
          el.classList.remove("wrapper_bottom-active")
        );

        const activeWrapperBottoms =
          swiper.slides[swiper.activeIndex].querySelectorAll(".wrapper_bottom");
        activeWrapperBottoms.forEach((el: any) =>
          el.classList.add("wrapper_bottom-active")
        );

        const wrapperTop = document.querySelectorAll(".wrapper_top");
        wrapperTop.forEach((el: any) =>
          el.classList.remove("wrapper_top-active")
        );

        const activeWrapperTop =
          swiper.slides[swiper.activeIndex].querySelectorAll(".wrapper_top");
        activeWrapperTop.forEach((el: any) =>
          el.classList.add("wrapper_top-active")
        );

        const icons = document.querySelectorAll(".icon");
        icons.forEach((el: any) => el.classList.remove("icon-active"));

        const activeIcons =
          swiper.slides[swiper.activeIndex].querySelectorAll(".icon");
        activeIcons.forEach((el: any) => el.classList.add("icon-active"));
      }}
    >
      {slideContent.map((content, index) => (
        <SwiperSlide
          key={index}
          className="swiper-slide"
        >
          <div className="wrapper">
            <div className="wrapper_top">
              <p className="title">{content.title}</p>
              <div className="icon">
                <img
                  src={content.image}
                  alt=""
                />
              </div>
            </div>
            <div className="wrapper_bottom">
              <span className="text">{content.text}</span>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CustomSwiper;
