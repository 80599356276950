import React, { useEffect, useState } from "react";
import { useJobContext } from "../../context/JobContext";
import style from "./ApplicationList.module.css";
import { Link } from "react-router-dom";
import { Pagination } from "../Pagination/Pagination";

export const ApplicationList = () => {
  const {
    jobs,
    currentPage,
    loadPage,
    filterDates = { startDate: new Date(), endDate: new Date() },
    itemsPerPage,
  } = useJobContext();
  const [displayJobs, setDisplayJobs] = useState<any[]>([]);

  useEffect(() => {
    if (filterDates.startDate && filterDates.endDate) {
      loadPage(currentPage, filterDates.startDate, filterDates.endDate);
    }
  }, [currentPage, filterDates, loadPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayJobs(jobs);
    }, 300);

    return () => clearTimeout(timer);
  }, [jobs]);

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const parts = date.toLocaleDateString("en-US", options).split("/");
    return `${parts[1]}.${parts[0]}.${parts[2]}`;
  };

  const getDateRangeString = () => {
    if (!filterDates) return "";

    const { startDate, endDate } = filterDates;

    if (startDate && (!endDate || startDate.getTime() === endDate.getTime())) {
      return `${formatDate(startDate)}`;
    } else if (startDate && endDate) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    } else {
      return "";
    }
  };

  return (
    <div
      className={`${itemsPerPage === 8 ? style.wrapper : style.wrapper_small}`}
    >
      <div className={style.header}>
        <div className={style.title_wrapper}>
          <h2 className={style.header_title}>
            Your applications for {getDateRangeString()}
          </h2>
          <Pagination />
        </div>
      </div>
      {displayJobs.length === 0 ? (
        <p className={style.no_application}>There are no applications yet</p>
      ) : (
        displayJobs.map((job) => (
          <Link target="_blank" to={job.link} className={style.link} key={job.id}>
            <div className={style.wrapper_content}>
              <div className={style.titles}>
                <span className={style.title}>Position</span>
                <span className={style.title}>Company</span>
              </div>
              <div className={style.content}>
                <h3 className={style.name}>{job.position_name}</h3>
                <h3 className={style.name}>{job.company_name}</h3>
              </div>
            </div>
          </Link>
        ))
      )}
    </div>
  );
};
