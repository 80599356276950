import React from "react";
import style from "./ExtensionModal.module.css";
import { useAuth } from "../../context/AuthContext";
import Cookies from "js-cookie";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router";

interface ExtensionModalProps {
  onClose: () => void;
}

const ExtensionModal: React.FC<ExtensionModalProps> = ({ onClose }) => {
  const { token } = useAuth();

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleStartApplication = () => {
    Cookies.set("extensionOpenCookie", "1");

    setTimeout(() => {
      Cookies.remove("extensionOpenCookie");
    }, 1000);

    window.postMessage({ action: "notifyContentScript" }, "*");
    window.addEventListener("message", (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { response, error } = event.data;
      if (response) {
        console.log("Ответ от контентного скрипта:", response);
      } else if (error) {
        console.error("Ошибка от контентного скрипта:", error);
      }
    });
    onClose();
  };

  return (
    <div
      className={style.modal}
      onClick={handleClickOutside}
    >
      <div className={style.modalContent}>
        <div className={style.header_wrapper}>
          <h3>Have you downloaded Raisly extension yet?</h3>
          <button
            onClick={onClose}
            className={style.closeButton}
          >
            <img
              src="/images/close.svg"
              alt="close modal window"
            />
          </button>
        </div>
        <div className={style.btn_wrapper}>
          <Button
            color="blue"
            text="Yes"
            onClick={handleStartApplication}
          />
          <Button
            color="white"
            text="No"
            onClick={() => {
              window.open(
                "https://chromewebstore.google.com/detail/raisly/cjhbelmcljmklhgjfopachdjagiamfhf?hl=uk",
                "_blank"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExtensionModal;
