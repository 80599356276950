import { createContext, ReactNode, useContext, useState } from "react";
import LogoutDialog from "../components/LogoutDialog/LogoutDialog";
import EditResumeDialog from "../components/EditResumeDialog/EditResumeDialog";
import CancelSubscriptionDialog from "../components/CancelSubscriptionDialog/CancelSubscriptionDialog";

interface DialogContextInterface {
    logoutDialogOpen: boolean;
    resumeDialogOpen: boolean;
    cancelSubDialogOpen: boolean;
    openCancelSubDialog: () => void;
    closeCancelSubDialog: () => void;
    openLogoutDialog: () => void;
    closeLogoutDialog: () => void;
    openResumeDialog: () => void;
    closeResumeDialog: () => void;
}

const DialogContext = createContext<DialogContextInterface | undefined>(undefined);

export const DialogProvider = ({ children }: { children: ReactNode }) => {
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const [resumeDialogOpen, setResumeDialogOpen] = useState(false);
    const [cancelSubDialogOpen, setCancelSubDialogOpen] = useState(false);

    const openCancelSubDialog = () => setCancelSubDialogOpen(true);
    const closeCancelSubDialog = () => setCancelSubDialogOpen(false);

    const openResumeDialog = () => setResumeDialogOpen(true);
    const closeResumeDialog = () => setResumeDialogOpen(false);

    const openLogoutDialog = () => setLogoutDialogOpen(true);
    const closeLogoutDialog = () => setLogoutDialogOpen(false);

    return (
        <DialogContext.Provider
            value={{
                resumeDialogOpen,
                logoutDialogOpen,
                openLogoutDialog,
                openResumeDialog,
                closeResumeDialog,
                closeLogoutDialog,
                cancelSubDialogOpen,
                openCancelSubDialog,
                closeCancelSubDialog,
            }}
        >
            {children}
            <LogoutDialog />
            <EditResumeDialog />
            <CancelSubscriptionDialog />
        </DialogContext.Provider>
    );
};

export const useDialog = (): DialogContextInterface => {
    const context = useContext(DialogContext);
    if (context === undefined) {
        throw new Error('useDialog must be used within a DialogProvider');
    }
    return context;
};
