import { CardItem } from "../CardItem/CardItem";
import styles from "./ReleasedSection.module.css";

type Props = {
  positionImages: string;
};

export const ReleasedSection: React.FC<Props> = ({ positionImages }) => {
  return (
    <>
      {positionImages === "right" ? (
        <div className={styles.wrapper}>
          <CardItem
            title="Create more time with Automated Job Applications"
            text="Our tool automatically applies to LinkedIn Easy Apply positions,
          saving you time for other things (e.g. networking, or preparing for an
          interview)."
          />
          <img
            src="/images/MacBook.png"
            alt="MacBook"
          />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <img
            src="/images/MacBook.png"
            alt="MacBook"
          />
          <CardItem
            title="Track progress and organize your job search with Application Tracker"
            text="Keep track of all your applications with our downloadable tracker, build it up as you like."
          />
        </div>
      )}
    </>
  );
};
