import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
export const BASE_SCHEME = 'https'
export const BASE_ORIGIN = "raislyhq.com"
export const BASE_URL = `${BASE_SCHEME}://${BASE_ORIGIN}` // `${BASE_SCHEME}://${BASE_ORIGIN}` // "http://0.0.0.0:8000/" //

const axiosInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
axiosInstance.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: any) => {
        if (error.response && error.response.data && error.response.data.message) {
            throw new Error(error.response.data.message);
        } else {
            throw error;
        }
    },
);

const axiosPrivateInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const axiosPrivateRefreshInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosPrivateRefreshInstance.interceptors.request.use((config) => {
    const token = Cookies.get('_auth_refresh');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

axiosPrivateInstance.interceptors.request.use((config) => {
    const token = Cookies.get('_auth');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

export { axiosInstance, axiosPrivateInstance };