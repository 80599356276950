import { Button } from "../Button/Button";
import style from "./OfferCard.module.css";

type Props = {
  saveCount: number;
  period: string;
  priceOld: number;
  priceNow: number;
  colorBtn: string;
  textBtn: string;
  onClick: () => void;
};

export const OfferCard: React.FC<Props> = ({
  saveCount,
  period,
  priceOld,
  priceNow,
  colorBtn,
  textBtn,
  onClick,
}) => {
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.wrapper_top}>
          <div className={style.save}>
            <span className={style.save_text}>{`Save ${saveCount}%`}</span>
          </div>

          <h3 className={style.period}>{period}</h3>
          <p className={style.subtitle}>For career development simplifying </p>
        </div>

        <div className={style.wrapper_bottom}>
          <div className={style.wrapper_price}>
            <span className={style.old_price}>${priceOld.toFixed(2)}</span>
            <span className={style.new_price}>${priceNow.toFixed(2)}</span>
            <span className={style.price_text}>per month</span>
          </div>
          <div className={style.btn}>
            <Button
              color={colorBtn}
              text={textBtn}
              onClick={onClick}
            />
          </div>

          <ul className={style.list}>
            <li className={style.list_item}>Apply for 150 jobs daily</li>
            <li className={style.list_item}>Fast applying</li>
            <li className={style.list_item}>
              Applying only to jobs relevant for you
            </li>
            <li className={style.list_item}>
              Track your applications in dashboard
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
