import React from 'react';

import styles from "./page.module.css";
import {Button} from "../../components/Button/Button";
import {useNavigate} from "react-router";

export const SuccessPage = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.signInPage}>
            <div className={styles.infoBlock}>
                <h1>Payment is successful</h1>
                <Button onClick={() => navigate("/dashboard")} color={"blue"} text={"Go to dashboard"} />
            </div>
        </div>
    );
};
