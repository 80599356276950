import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import ExportModal from "../ExportModal/ExportModal";
import style from "./DashboardHeader.module.css";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { useNavigate } from "react-router";
import { useProfile } from "../../context/ProfileContext";
import { Link } from "react-router-dom";
import EditResumeDialog from "../EditResumeDialog/EditResumeDialog";
import { useJobContext } from "../../context/JobContext";
import { useDialog } from "../../context/DialogContext";
import ExtensionModal from "../ExtensionModal/ExtensionModal";

export const DashboardHeader = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isExtensionModalOpen, setIsExtensionModalOpen] = useState(false);
  const { openResumeDialog } = useDialog();

  const { profileData, handleGetProfileData } = useProfile();
  const { jobs } = useJobContext();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetProfileData();
  }, []);

  const handleEditClick = () => {
    setEditModalOpen(true);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleOpenExtensionModal = () => {
    setIsExtensionModalOpen(true);
  };
  const handleCloseExtensionModal = () => {
    setIsExtensionModalOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <div className={style.header}>
        <LogoIcon
          className={style.logo}
          onClick={() => navigate("/")}
        />
        <Link
          to="/profile"
          className={style.link}
        >
          <div className={style.user}>
            <User />
            <p className={style.name_user}>{profileData?.fullName}</p>
          </div>
        </Link>
      </div>

      <div className={style.wrapper}>
        <h2 className={style.title}>Dashboard</h2>
        <div className={style.btn_wrapper}>
          <button
            className={style.edit}
            onClick={handleEditClick}
          >
            <img
              src="./images/Edit.svg"
              alt="edit icon"
            />
            <span
              className={style.btn_text}
              onClick={openResumeDialog}
            >
              Edit info about me
            </span>
          </button>

          <div className={style.btn}>
            <Button
              color="white"
              text="Export your applications"
              imgSrc="./images/Export.svg"
              className={style.special_btn}
              onClick={handleOpenModal}
            />
          </div>
          <div className={style.btn}>
            <Button
              color="blue"
              text="Start application"
              onClick={handleOpenExtensionModal}
            />
          </div>
        </div>
        {isModalOpen && (
          <ExportModal
            vacancies={jobs}
            onClose={handleCloseModal}
          />
        )}
        {isExtensionModalOpen && (
          <ExtensionModal onClose={handleCloseExtensionModal} />
        )}
        {isEditModalOpen && <EditResumeDialog />}
      </div>
    </div>
  );
};
